import React from 'react';

const BankSafe = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#6e6e8e" d="M109,109H39c-5.5,0-10-4.5-10-10V29c0-5.5,4.5-10,10-10h70c5.5,0,10,4.5,10,10v70C119,104.5,114.5,109,109,109z"/><path fill="#d4d3dd" d="M89,109H19c-5.5,0-10-4.5-10-10V29c0-5.5,4.5-10,10-10h70c5.5,0,10,4.5,10,10v70C99,104.5,94.5,109,89,109z"/><path fill="#f2f2f4" d="M19,97V31c0-1.1,0.9-2,2-2h66c1.1,0,2,0.9,2,2v66c0,1.1-0.9,2-2,2H21C19.9,99,19,98.1,19,97z"/><path fill="none" stroke="#444b54" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="6" d="M89 19h20c5.5 0 10 4.5 10 10M119 29v70c0 5.5-4.5 10-10 10v5M89 109v5M19 109v5"/><path fill="#88a" d="M79 32A7 7 0 1 0 79 46A7 7 0 1 0 79 32Z"/><path fill="#444b54" d="M79 36A3 3 0 1 0 79 42A3 3 0 1 0 79 36Z"/><path fill="none" stroke="#444b54" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="6" d="M104 39h-5V29c0-5.5-4.5-10-10-10H19c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10V89h5M99 50L99 78"/><path fill="#fff" d="M54 54A10 10 0 1 0 54 74A10 10 0 1 0 54 54Z" transform="rotate(-45.001 54 64)"/><path fill="none" stroke="#444b54" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="6" d="M54 54A10 10 0 1 0 54 74A10 10 0 1 0 54 54Z" transform="rotate(-45.001 54 64)"/><path fill="none" stroke="#444b54" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="6" d="M46.9 56.9L39.9 49.9M61.1 56.9L68.1 49.9M61.1 71.1L68.1 78.1M46.9 71.1L39.9 78.1"/></svg>
  );
};

BankSafe.defaultProps = {
  width: 30
};

export default BankSafe;
