import React from 'react';

const TransactionList3 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#dfe8f4" d="M101,11c5.5,0,10,4.5,10,10v98c0,2.8-2.2,5-5,5H27c-5.5,0-10-4.5-10-10V21c0-5.5,4.5-10,10-10H101z"/><path fill="#fff" d="M89,57H55c-1.7,0-3-1.3-3-3s1.3-3,3-3h34c1.7,0,3,1.3,3,3S90.7,57,89,57z"/><path fill="#444b54" d="M84,127H27c-7.2,0-13-5.8-13-13V21c0-7.2,5.8-13,13-13h10c1.7,0,3,1.3,3,3s-1.3,3-3,3H27c-3.9,0-7,3.1-7,7v93 c0,3.9,3.1,7,7,7h57c1.7,0,3,1.3,3,3S85.7,127,84,127z"/><path fill="#fff" d="M72,17.5H56c-3.9,0-7-3.1-7-7v0c0-3.9,3.1-7,7-7h16c3.9,0,7,3.1,7,7v0C79,14.4,75.9,17.5,72,17.5z"/><path fill="#444b54" d="M101 8H81.7c-1.1-4.3-5-7.5-9.7-7.5H56c-5.5 0-10 4.5-10 10s4.5 10 10 10h16c4.3 0 7.9-2.7 9.4-6.5H101c3.9 0 7 3.1 7 7v40c0 1.7 1.3 3 3 3s3-1.3 3-3V21C114 13.8 108.2 8 101 8zM72 14.5H56c-2.2 0-4-1.8-4-4s1.8-4 4-4h16c2.2 0 4 1.8 4 4S74.2 14.5 72 14.5zM122.1 112.9c0-3.7-1.8-7.1-4.8-9.2l-9.2-6.5c-1.3-1-2.1-2.5-2.1-4.2 0-2.8 2.3-5.1 5.1-5.1 2.1 0 3.9 1.2 4.7 3.1.6 1.5 2.4 2.2 3.9 1.6 1.5-.6 2.2-2.4 1.6-3.9-1.3-3.2-4-5.5-7.2-6.4V82c0-1.7-1.3-3-3-3s-3 1.3-3 3v.4c-4.7 1.3-8.1 5.6-8.1 10.7 0 3.6 1.7 6.9 4.7 9l9.3 6.5c1.4 1 2.3 2.5 2.3 4.2 0 2.8-2.3 5.1-5.1 5.1-2 0-3.9-1.2-4.7-3.1-.7-1.5-2.4-2.2-3.9-1.6-1.5.7-2.2 2.4-1.6 3.9 1.4 3.1 4 5.4 7.2 6.3v.4c0 1.7 1.3 3 3 3s3-1.3 3-3v-.4C118.7 122.3 122.1 118 122.1 112.9z"/><path fill="#fff" d="M40 51A3 3 0 1 0 40 57 3 3 0 1 0 40 51zM89 72H55c-1.7 0-3-1.3-3-3s1.3-3 3-3h34c1.7 0 3 1.3 3 3S90.7 72 89 72zM40 66A3 3 0 1 0 40 72 3 3 0 1 0 40 66zM89 87H55c-1.7 0-3-1.3-3-3s1.3-3 3-3h34c1.7 0 3 1.3 3 3S90.7 87 89 87zM40 81A3 3 0 1 0 40 87 3 3 0 1 0 40 81z"/></svg>
  );
};

TransactionList3.defaultProps = {
  width: 30
};

export default TransactionList3;
