import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import JsonLd from '../JsonLd';

const CORPORATION_QUERY = graphql`
  query {
      contentfulSeoCorporation {
      name
      url
      facebookUrl
      telephone
      streetAddress
      addressLocality
      addressRegion
      postalCode
    }
  }
`;

const Corporation = () => {
  const { contentfulSeoCorporation: corporation } = useStaticQuery(CORPORATION_QUERY);

  const data = React.useMemo(() => ({
    "@context": "http://schema.org",
    "@type": "Corporation",
    "name": corporation.name,
    "url": corporation.url,
    "sameAs": [corporation.facebookUrl],
    "telephone": corporation.telephone,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": corporation.streetAddress,
      "addressLocality": corporation.addressLocality,
      "addressRegion": corporation.addressRegion,
      "postalCode": corporation.postalCode
    }
  }), [corporation]);

  return (
    <JsonLd children={data} />
  );
};

export default Corporation;
