import React from 'react';

const BankCards3 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M109,106H35c-5.5,0-10-4.5-10-10V48c0-5.5,4.5-10,10-10h74c5.5,0,10,4.5,10,10v48 C119,101.5,114.5,106,109,106"/><path fill="#454b54" d="M118.2,38.8c-2.3-2.3-5.6-3.8-9.2-3.8H35c-3.6,0-6.8,1.5-9.2,3.8C23.5,41.2,22,44.4,22,48v48 c0,3.6,1.5,6.8,3.8,9.2c2.3,2.3,5.6,3.8,9.2,3.8h74c3.6,0,6.8-1.5,9.2-3.8c2.3-2.3,3.8-5.6,3.8-9.2V48 C122,44.4,120.5,41.2,118.2,38.8z M116,96c0,1.9-0.8,3.7-2.1,4.9c-1.3,1.3-3,2-4.9,2.1H35c-1.9,0-3.7-0.8-4.9-2.1 c-1.3-1.3-2-3-2.1-4.9V48c0-1.9,0.8-3.7,2.1-4.9c1.3-1.3,3-2,4.9-2.1h74c1.9,0,3.7,0.8,4.9,2.1c1.3,1.3,2,3,2.1,4.9V96z"/><path fill="#ccc" d="M109,41H35c-0.2,0-0.3,0-0.5,0c-3.4,0.3-6.2,3-6.5,6.5c0,0.2,0,0.3,0,0.5v48l0,0c0,0.1,0,0.1,0,0.2 c0,1,0.3,2,0.7,2.8H93c10.5,0,19-8.5,19-19V41.7c-0.9-0.4-1.8-0.7-2.8-0.7C109.1,41,109.1,41,109,41"/><path fill="#addff8" stroke="#454b54" stroke-miterlimit="10" stroke-width="6" d="M93,90H19c-5.5,0-10-4.5-10-10V32 c0-5.5,4.5-10,10-10h74c5.5,0,10,4.5,10,10v48C103,85.5,98.5,90,93,90z"/><path fill="#454b54" d="M47,79H25c-1.7,0-3-1.3-3-3l0,0c0-1.7,1.3-3,3-3h22c1.7,0,3,1.3,3,3l0,0C50,77.7,48.7,79,47,79z"/><path fill="#fff" d="M87,46H55c-1.7,0-3-1.3-3-3l0,0c0-1.7,1.3-3,3-3h32c1.7,0,3,1.3,3,3l0,0C90,44.7,88.7,46,87,46z"/><path fill="#454b54" d="M87,67h-8c-1.7,0-3-1.3-3-3l0,0c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3l0,0C90,65.7,88.7,67,87,67z"/><path fill="#f7f3b1" d="M38,52H27c-2.8,0-5-2.2-5-5v-7c0-2.8,2.2-5,5-5h11c2.8,0,5,2.2,5,5v7C43,49.8,40.8,52,38,52z"/><path fill="#454b54" d="M33 67h-8c-1.7 0-3-1.3-3-3l0 0c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3l0 0C36 65.7 34.7 67 33 67zM69 67h-8c-1.7 0-3-1.3-3-3l0 0c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3l0 0C72 65.7 70.7 67 69 67zM51 67h-8c-1.7 0-3-1.3-3-3l0 0c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3l0 0C54 65.7 52.7 67 51 67z"/></svg>
  );
};

BankCards3.defaultProps = {
  width: 30
};

export default BankCards3;
