import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import chunk from 'lodash/chunk';
import Link from '../Link/Link';
import AgencyLogos from './AgencyLogos';

const FOOTER_QUERY = graphql`
  query FooterQuery {
    contentfulFooter {
      id
      copyrightText
      legalText
      routingNumber
      facebookLink
      nmlsId
      sections {
        label
        links {
          label
          url
        }
      }
    }
  }
`;

const Footer = () => {
  const { contentfulFooter: footerData } = useStaticQuery(FOOTER_QUERY);
  const { sections, copyrightText, legalText, routingNumber, facebookLink, nmlsId } = footerData;

  const groups = chunk(sections, 2);
  const currentYear = React.useMemo(() => (new Date().getFullYear()), []);

  return (
    <div className="bg-navy-base mt-auto">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="md:grid md:grid-cols-2 md:gap-8 xl:col-span-2">
            {groups.map(group => (
              <div key={group[0].label} className="grid grid-cols-2 gap-8">
                {group.map(section => (
                  <div key={section.label}>
                    <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                      {section.label}
                    </h4>
                    <ul className="mt-4 mb-8 xl:mb-0">
                      {section.links.map(link => {
                        if (section.label === "Locations") {
                          return (
                            <li key={link.label} className="mb-4">
                              <p className="text-base numeric block mb-4 leading-6 text-gray-300">{link.label}</p>
                            </li>
                          );
                        }

                        return (
                          <li key={link.label}>
                            <Link to={link.url} className="text-base numeric block mb-4 leading-6 text-gray-300 hover:text-white">
                              {link.label}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="mt-2 xl:mt-0">
            <AgencyLogos />
            <p className="mt-4 text-gray-300">{legalText}</p>
            <p className="mt-4 text-gray-300">{routingNumber}</p>
            <p className="mt-2 text-gray-300">{nmlsId}</p>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex md:order-2">
            <a href={facebookLink} className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
              </svg>
            </a>
          </div>
          <p className="mt-8 text-base leading-6 text-gray-400 md:mt-0 md:order-1">
            &copy; {currentYear} {copyrightText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
