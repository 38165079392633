import React from 'react';

const PosTerminal = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M99,4H29c-5.5,0-10,4.5-10,10v34c0,5.5,4.5,10,10,10v56c0,5.5,4.5,10,10,10h50c5.5,0,10-4.5,10-10V58c5.5,0,10-4.5,10-10V14C109,8.5,104.5,4,99,4z"/><path fill="#444b54" d="M97,49H31c-1.7,0-3-1.3-3-3V16c0-1.7,1.3-3,3-3h66c1.7,0,3,1.3,3,3v30C100,47.7,98.7,49,97,49z M34,43h60V19H34V43z"/><path fill="#444b54" d="M105,1h-6H29c-7.2,0-13,5.8-13,13v34c0,6.1,4.3,11.3,10,12.6V114c0,7.2,5.8,13,13,13h1h5h44h1h5c7.2,0,13-5.8,13-13V60.6c5.7-1.4,10-6.5,10-12.6V14C118,6.8,112.2,1,105,1z M29,55c-3.9,0-7-3.1-7-7V14c0-3.9,3.1-7,7-7h70c3.9,0,7,3.1,7,7v34c0,3.9-3.1,7-7,7H29z M37,115L37,115c0,2.9-4.2,3.4-4.8,0.6c-0.1-0.5-0.2-1-0.2-1.6V64c0-1.7,1.3-3,3-3h58c1.7,0,3,1.3,3,3v45c0,1.7-1.3,3-3,3H40C38.3,112,37,113.3,37,115z"/><path fill="#444b54" d="M58 68H41c-1.7 0-3 1.3-3 3v10c0 1.7 1.3 3 3 3h17c1.7 0 3-1.3 3-3V71C61 69.3 59.7 68 58 68zM58 90H41c-1.7 0-3 1.3-3 3v10c0 1.7 1.3 3 3 3h17c1.7 0 3-1.3 3-3V93C61 91.3 59.7 90 58 90zM87 68H70c-1.7 0-3 1.3-3 3v10c0 1.7 1.3 3 3 3h17c1.7 0 3-1.3 3-3V71C90 69.3 88.7 68 87 68zM87 90H70c-1.7 0-3 1.3-3 3v10c0 1.7 1.3 3 3 3h17c1.7 0 3-1.3 3-3V93C90 91.3 88.7 90 87 90z"/></svg>
  );
};

PosTerminal.defaultProps = {
  width: 30
};

export default PosTerminal;
