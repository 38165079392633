import React from 'react';
import PropTypes from 'prop-types';
import Portal from '../../utils/Portal';
import ExternalLinkModal from './ExternalLinkModal';

const ExternalLink = ({ to, className, children }) => {
  const [isOpen, setOpen] = React.useState(false);

  const toggleModal = React.useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);


  const handleLinkClick = React.useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, [setOpen]);

  return (
    <React.Fragment>
      <a
        href={to}
        rel="noopener"
        className={className}
        onClick={handleLinkClick}
      >
        {children}
      </a>
      {isOpen && (
        <Portal container={document.querySelector('#modal-container')}>
          <ExternalLinkModal toggleModal={toggleModal} to={to} />
        </Portal>
      )}
    </React.Fragment>
  );
};

ExternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any
};

export default ExternalLink;
