import React from 'react';
import { useClassNames } from './Class';

export const makeVariant = (Element, variantClass) => {
  const VariantComponent = ({ className, ...props }) => {
    const extendedClass = useClassNames(variantClass, className);

    return (
      <Element className={extendedClass} {...props} />
    );
  };

  VariantComponent.propTypes = Element.propTypes;
  VariantComponent.defaultProps = Element.defaultProps;
  return VariantComponent;
};
