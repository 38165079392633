import React from 'react';

const Logo = () => (
  <svg width="100%" viewBox="0 0 404 166" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M305.09 2L291.84 65.13C289.74 75.33 287.663 82.56 285.61 86.82C283.775 90.8397 280.748 94.1981 276.94 96.44C273.207 98.5933 268.473 99.67 262.74 99.67C256.38 99.67 251.533 98.1833 248.2 95.21C246.583 93.8113 245.296 92.0716 244.431 90.116C243.566 88.1604 243.146 86.0375 243.2 83.9C243.205 83.1549 243.249 82.4106 243.33 81.67C243.43 80.94 243.66 79.48 243.66 79.48L244.74 74.34H221C220.65 74.88 220.15 75.93 220.15 75.93C217.63 83.77 213.807 89.6867 208.68 93.68C203.896 97.5564 197.927 99.6742 191.77 99.68C188.614 99.7321 185.487 99.0797 182.615 97.7701C179.743 96.4605 177.2 94.5269 175.17 92.11C170.73 87.0633 168.51 79.9133 168.51 70.66C168.56 61.9323 170.298 53.2968 173.63 45.23C177.05 36.7433 181.61 30.5 187.31 26.5C192.665 22.6179 199.106 20.5189 205.72 20.5C211.72 20.5 216.52 22.0133 220.12 25.04C223.72 28.0667 226.15 32.94 227.41 39.66L227.14 44.51H251L259.83 2H236.09L234.71 8.61C227.24 2.89 217.76 0 206.23 0C201.088 0.00513169 195.969 0.677411 191 2H167.9L120 89.42L101.18 2H77.45L102.32 117.6H127.71L146.33 83.82C148.04 93.3 151.59 101.02 157.06 106.82C165.06 115.34 176.113 119.6 190.22 119.6C202.78 119.6 213.74 116.17 223.1 109.31C224.55 108.24 225.93 107.08 227.27 105.87C228.33 107.203 229.486 108.457 230.73 109.62C237.877 116.22 248.18 119.52 261.64 119.52C272.893 119.52 282.2 117.313 289.56 112.9C296.55 108.842 302.249 102.889 306 95.73C309.62 88.6767 312.827 78.5033 315.62 65.21L328.82 2H305.09Z" fill="#3155A4"/>
    <path d="M27.88 50.01H9.28L13.36 68.62H31.97L27.88 50.01Z" fill="#EF464D"/>
    <path d="M54.02 50.01H35.41L39.5 68.62H58.1L54.02 50.01Z" fill="#EF464D"/>
    <path d="M80.21 50.01H61.6L65.68 68.62H84.29L80.21 50.01Z" fill="#EF464D"/>
    <path d="M242.74 68.62H224.13L228.21 50.01H246.82L242.74 68.62Z" fill="#EF464D"/>
    <path d="M340.95 68.62H322.35L326.43 50.01H345.04L340.95 68.62Z" fill="#EF464D"/>
    <path d="M367.08 68.62H348.48L352.56 50.01H371.17L367.08 68.62Z" fill="#EF464D"/>
    <path d="M393.25 68.62H374.65L378.73 50.01H397.34L393.25 68.62Z" fill="#EF464D"/>
    <path d="M25.2198 137.109C24.9225 137.121 24.5624 137.189 24.1393 137.315C23.7163 137.429 23.3676 137.572 23.0932 137.744C22.7045 137.984 22.3901 138.293 22.15 138.67C21.9213 139.047 21.6926 139.493 21.464 140.008C20.6979 141.825 19.5489 144.661 18.0169 148.514C16.4848 152.355 14.907 156.363 13.2835 160.536H11.8601C10.2137 156.111 8.62448 151.847 7.09243 147.742C5.56038 143.638 4.51996 140.865 3.97117 139.424C3.83397 139.07 3.65104 138.761 3.42237 138.498C3.19371 138.224 2.89073 137.967 2.51344 137.727C2.20474 137.532 1.86174 137.389 1.48445 137.298C1.10715 137.195 0.758438 137.132 0.438309 137.109V135.892H10.5052V137.109C9.64775 137.155 8.92175 137.241 8.32722 137.367C7.74413 137.492 7.45258 137.715 7.45258 138.035C7.45258 138.161 7.47544 138.31 7.52118 138.481C7.56691 138.653 7.60693 138.79 7.64123 138.893C8.06425 140.013 8.75025 141.843 9.6992 144.381C10.6482 146.908 11.9973 150.509 13.7466 155.185C14.6155 153.07 15.6159 150.572 16.7478 147.691C17.8797 144.81 18.64 142.832 19.0287 141.757C19.406 140.716 19.6232 139.996 19.6804 139.596C19.749 139.196 19.7833 138.87 19.7833 138.618C19.7833 138.355 19.6632 138.138 19.4231 137.967C19.1945 137.784 18.8858 137.629 18.4971 137.504C18.1198 137.389 17.7367 137.304 17.348 137.246C16.9593 137.189 16.5648 137.144 16.1647 137.109V135.892H25.2198V137.109Z" fill="#3155A4"/>
    <path d="M49.6541 160.939H40.5898V159.851C41.3874 159.799 42.0469 159.697 42.5683 159.544C43.1 159.39 43.3659 159.201 43.3659 158.976C43.3659 158.884 43.3556 158.772 43.3352 158.639C43.3147 158.506 43.2841 158.388 43.2432 158.286L41.4334 153.409H33.8108C33.5245 154.125 33.2893 154.748 33.1053 155.28C32.9315 155.812 32.773 156.303 32.6298 156.752C32.4969 157.192 32.4049 157.55 32.3538 157.826C32.3027 158.102 32.2771 158.327 32.2771 158.501C32.2771 158.91 32.5992 159.227 33.2433 159.452C33.8875 159.677 34.6135 159.81 35.4212 159.851V160.939H27.2312V159.851C27.497 159.83 27.8293 159.774 28.2281 159.682C28.6269 159.58 28.954 159.447 29.2097 159.283C29.6187 159.007 29.9356 158.721 30.1606 158.424C30.3855 158.117 30.6053 157.698 30.8201 157.167C31.9141 154.437 33.1206 151.369 34.4396 147.964C35.7586 144.559 36.9345 141.528 37.9672 138.869H39.1942L46.4486 157.642C46.602 158.041 46.7758 158.363 46.9701 158.608C47.1644 158.854 47.4353 159.094 47.783 159.329C48.0181 159.472 48.3249 159.595 48.7032 159.697C49.0815 159.789 49.3985 159.84 49.6541 159.851V160.939ZM40.8812 152.044L37.5838 143.609L34.3476 152.044H40.8812Z" fill="#3155A4"/>
    <path d="M67.9053 154.467L67.4452 160.939H50.4363V159.851C50.6817 159.83 51.0344 159.794 51.4946 159.743C51.9649 159.692 52.2921 159.626 52.4761 159.544C52.7829 159.411 53.0027 159.217 53.1356 158.961C53.2788 158.705 53.3504 158.378 53.3504 157.979V142.397C53.3504 142.049 53.289 141.742 53.1663 141.477C53.0538 141.211 52.8238 140.996 52.4761 140.832C52.241 140.71 51.9087 140.592 51.4792 140.48C51.06 140.357 50.7124 140.285 50.4363 140.265V139.176H59.6232V140.265C59.337 140.275 58.9638 140.326 58.5036 140.418C58.0537 140.51 57.7266 140.592 57.5221 140.664C57.1744 140.786 56.9341 140.996 56.8012 141.293C56.6785 141.589 56.6172 141.906 56.6172 142.243V156.86C56.6172 157.535 56.6478 158.061 56.7092 158.44C56.7808 158.808 56.9392 159.094 57.1846 159.298C57.4198 159.482 57.7675 159.605 58.2276 159.667C58.6979 159.718 59.3523 159.743 60.1907 159.743C60.569 159.743 60.9627 159.733 61.3717 159.713C61.7807 159.692 62.1692 159.651 62.5373 159.59C62.8952 159.528 63.2224 159.447 63.5189 159.344C63.8256 159.232 64.0506 159.094 64.1937 158.93C64.6129 158.46 65.0986 157.749 65.6507 156.798C66.2131 155.837 66.6017 155.06 66.8164 154.467H67.9053Z" fill="#3155A4"/>
    <path d="M86.862 154.467L86.4019 160.939H69.393V159.851C69.6384 159.83 69.9912 159.794 70.4513 159.743C70.9216 159.692 71.2488 159.626 71.4329 159.544C71.7396 159.411 71.9594 159.217 72.0924 158.961C72.2355 158.705 72.3071 158.378 72.3071 157.979V142.397C72.3071 142.049 72.2457 141.742 72.123 141.477C72.0106 141.211 71.7805 140.996 71.4329 140.832C71.1977 140.71 70.8654 140.592 70.4359 140.48C70.0167 140.357 69.6691 140.285 69.393 140.265V139.176H78.58V140.265C78.2937 140.275 77.9205 140.326 77.4604 140.418C77.0105 140.51 76.6833 140.592 76.4788 140.664C76.1311 140.786 75.8909 140.996 75.7579 141.293C75.6352 141.589 75.5739 141.906 75.5739 142.243V156.86C75.5739 157.535 75.6046 158.061 75.6659 158.44C75.7375 158.808 75.896 159.094 76.1414 159.298C76.3765 159.482 76.7242 159.605 77.1843 159.667C77.6546 159.718 78.309 159.743 79.1474 159.743C79.5258 159.743 79.9194 159.733 80.3284 159.713C80.7374 159.692 81.1259 159.651 81.494 159.59C81.8519 159.528 82.1791 159.447 82.4756 159.344C82.7824 159.232 83.0073 159.094 83.1504 158.93C83.5697 158.46 84.0553 157.749 84.6075 156.798C85.1698 155.837 85.5584 155.06 85.7731 154.467H86.862Z" fill="#3155A4"/>
    <path d="M106.647 154.529L106.233 160.939H88.1657V159.851C88.4724 159.83 88.8814 159.789 89.3927 159.728C89.9039 159.656 90.2362 159.585 90.3896 159.513C90.7168 159.36 90.9417 159.16 91.0644 158.915C91.1973 158.67 91.2638 158.358 91.2638 157.979V142.336C91.2638 141.998 91.2076 141.702 91.0951 141.446C90.9826 141.18 90.7475 140.965 90.3896 140.802C90.1135 140.669 89.7403 140.551 89.27 140.449C88.8099 140.347 88.4418 140.285 88.1657 140.265V139.176H105.006V144.421H103.856C103.651 143.644 103.206 142.821 102.521 141.952C101.846 141.073 101.202 140.592 100.589 140.51C100.272 140.47 99.8935 140.439 99.4538 140.418C99.0142 140.398 98.5132 140.388 97.9508 140.388H94.5306V149.038H96.9846C97.7719 149.038 98.3445 148.966 98.7023 148.823C99.0704 148.67 99.3925 148.419 99.6686 148.072C99.8935 147.775 100.083 147.381 100.236 146.891C100.4 146.39 100.507 145.919 100.558 145.48H101.708V153.992H100.558C100.517 153.501 100.405 152.985 100.221 152.443C100.047 151.891 99.8628 151.492 99.6686 151.246C99.3516 150.848 98.9886 150.587 98.5796 150.464C98.1809 150.342 97.6492 150.28 96.9846 150.28H94.5306V156.814C94.5306 157.468 94.5766 157.985 94.6687 158.363C94.7607 158.741 94.9396 159.027 95.2055 159.222C95.4713 159.416 95.8343 159.544 96.2944 159.605C96.7545 159.656 97.4089 159.682 98.2575 159.682C98.595 159.682 99.0397 159.682 99.5919 159.682C100.144 159.682 100.609 159.661 100.988 159.62C101.376 159.58 101.775 159.508 102.184 159.406C102.603 159.293 102.9 159.145 103.073 158.961C103.544 158.47 104.035 157.729 104.546 156.737C105.067 155.735 105.4 154.999 105.543 154.529H106.647Z" fill="#3155A4"/>
    <path d="M127.521 140.265C127.347 140.275 127.071 140.347 126.693 140.48C126.314 140.602 126.043 140.725 125.88 140.848C125.481 141.093 125.21 141.282 125.067 141.415C124.924 141.548 124.648 141.896 124.239 142.458C123.441 143.583 122.705 144.723 122.03 145.878C121.355 147.034 120.512 148.501 119.5 150.28C119.121 150.935 118.871 151.461 118.748 151.86C118.625 152.259 118.564 152.836 118.564 153.593V157.749C118.564 158.117 118.636 158.44 118.779 158.716C118.932 158.981 119.188 159.196 119.546 159.36C119.76 159.442 120.144 159.544 120.696 159.667C121.248 159.779 121.693 159.84 122.03 159.851V160.939H111.846V159.851C112.133 159.83 112.562 159.794 113.135 159.743C113.707 159.682 114.106 159.605 114.331 159.513C114.699 159.36 114.949 159.15 115.082 158.884C115.226 158.618 115.297 158.291 115.297 157.903V152.611C115.297 152.448 115.195 152.151 114.99 151.722C114.796 151.282 114.561 150.797 114.285 150.265C113.671 149.079 112.991 147.796 112.245 146.415C111.499 145.035 110.849 143.854 110.297 142.872C109.96 142.29 109.658 141.85 109.392 141.553C109.137 141.257 108.84 140.996 108.503 140.771C108.257 140.618 107.961 140.5 107.613 140.418C107.276 140.337 106.969 140.285 106.693 140.265V139.176H115.972V140.265C114.929 140.285 114.193 140.357 113.763 140.48C113.334 140.602 113.119 140.776 113.119 141.001C113.119 141.063 113.15 141.17 113.211 141.323C113.273 141.477 113.36 141.671 113.472 141.906C113.605 142.172 113.763 142.479 113.947 142.826C114.132 143.164 114.305 143.491 114.469 143.808C114.949 144.718 115.44 145.628 115.941 146.538C116.442 147.438 117.097 148.614 117.904 150.065C119.51 147.479 120.732 145.464 121.57 144.023C122.419 142.571 122.843 141.702 122.843 141.415C122.843 141.18 122.756 140.991 122.582 140.848C122.408 140.705 122.178 140.592 121.892 140.51C121.596 140.429 121.274 140.377 120.926 140.357C120.578 140.326 120.256 140.296 119.96 140.265V139.176H127.521V140.265Z" fill="#3155A4"/>
    <path d="M160.759 160.793C159.307 160.793 157.912 160.507 156.575 159.936C155.249 159.364 154.071 158.541 153.042 157.466C152.024 156.391 151.213 155.082 150.607 153.539C150.001 151.984 149.698 150.235 149.698 148.291C149.698 146.347 149.995 144.587 150.589 143.009C151.184 141.42 152.007 140.053 153.059 138.91C154.099 137.778 155.329 136.903 156.746 136.286C158.164 135.669 159.679 135.36 161.291 135.36C162.491 135.36 163.578 135.509 164.549 135.806C165.521 136.103 166.447 136.52 167.328 137.058L168.031 135.892H169.403L169.557 144.569H168.151C167.968 143.723 167.694 142.809 167.328 141.825C166.962 140.831 166.533 139.99 166.041 139.304C165.493 138.527 164.841 137.921 164.086 137.487C163.332 137.041 162.469 136.818 161.497 136.818C160.388 136.818 159.353 137.064 158.393 137.555C157.444 138.047 156.62 138.773 155.923 139.733C155.26 140.659 154.74 141.837 154.362 143.266C153.985 144.695 153.796 146.29 153.796 148.051C153.796 149.606 153.991 151.035 154.38 152.338C154.78 153.63 155.346 154.768 156.077 155.751C156.809 156.723 157.672 157.483 158.667 158.032C159.673 158.581 160.794 158.855 162.028 158.855C162.954 158.855 163.806 158.712 164.584 158.426C165.361 158.129 166.036 157.74 166.607 157.26C167.179 156.757 167.682 156.186 168.117 155.545C168.551 154.905 168.905 154.208 169.18 153.453L170.466 154.07C169.483 156.483 168.179 158.209 166.556 159.25C164.932 160.279 163 160.793 160.759 160.793Z" fill="#3155A4"/>
    <path d="M196.189 160.939H190.33C189.032 158.905 187.856 157.146 186.803 155.664C185.76 154.181 184.625 152.652 183.398 151.078H181.174V157.979C181.174 158.337 181.23 158.654 181.343 158.93C181.455 159.196 181.695 159.401 182.063 159.544C182.248 159.615 182.57 159.682 183.03 159.743C183.49 159.805 183.878 159.84 184.195 159.851V160.939H174.993V159.851C175.238 159.83 175.591 159.794 176.051 159.743C176.522 159.692 176.849 159.626 177.033 159.544C177.34 159.411 177.559 159.217 177.692 158.961C177.836 158.705 177.907 158.378 177.907 157.979V142.244C177.907 141.886 177.856 141.569 177.754 141.293C177.651 141.017 177.411 140.807 177.033 140.664C176.777 140.572 176.445 140.49 176.036 140.418C175.627 140.337 175.279 140.285 174.993 140.265V139.176H185.039C185.959 139.176 186.823 139.273 187.631 139.467C188.439 139.652 189.17 139.953 189.824 140.372C190.458 140.781 190.964 141.318 191.342 141.983C191.721 142.637 191.91 143.43 191.91 144.36C191.91 145.198 191.782 145.94 191.526 146.584C191.271 147.228 190.893 147.785 190.392 148.256C189.931 148.695 189.379 149.079 188.735 149.406C188.091 149.723 187.38 149.994 186.603 150.219C187.677 151.64 188.566 152.836 189.272 153.808C189.988 154.779 190.862 155.96 191.895 157.351C192.344 157.964 192.748 158.419 193.106 158.716C193.474 159.002 193.837 159.237 194.195 159.421C194.461 159.554 194.793 159.656 195.192 159.728C195.591 159.799 195.923 159.84 196.189 159.851V160.939ZM188.122 144.713C188.122 143.394 187.743 142.351 186.987 141.584C186.23 140.807 185.162 140.418 183.781 140.418H181.174V149.759H183.183C184.594 149.759 185.77 149.309 186.711 148.409C187.651 147.499 188.122 146.267 188.122 144.713Z" fill="#3155A4"/>
    <path d="M215.345 154.529L214.931 160.939H196.864V159.851C197.171 159.83 197.58 159.789 198.091 159.728C198.602 159.656 198.934 159.585 199.088 159.513C199.415 159.36 199.64 159.16 199.763 158.915C199.895 158.67 199.962 158.358 199.962 157.979V142.336C199.962 141.998 199.906 141.702 199.793 141.446C199.681 141.18 199.446 140.965 199.088 140.802C198.812 140.669 198.438 140.551 197.968 140.449C197.508 140.347 197.14 140.285 196.864 140.265V139.176H213.704V144.421H212.554C212.349 143.644 211.904 142.821 211.219 141.952C210.545 141.073 209.9 140.592 209.287 140.51C208.97 140.47 208.592 140.439 208.152 140.418C207.712 140.398 207.211 140.388 206.649 140.388H203.229V149.038H205.683C206.47 149.038 207.043 148.966 207.4 148.823C207.769 148.67 208.091 148.419 208.367 148.072C208.592 147.775 208.781 147.381 208.934 146.891C209.098 146.39 209.205 145.919 209.256 145.48H210.407V153.992H209.256C209.215 153.501 209.103 152.985 208.919 152.443C208.745 151.891 208.561 151.492 208.367 151.246C208.05 150.848 207.687 150.587 207.278 150.464C206.879 150.342 206.347 150.28 205.683 150.28H203.229V156.814C203.229 157.468 203.275 157.985 203.367 158.363C203.459 158.741 203.638 159.027 203.904 159.222C204.169 159.416 204.532 159.544 204.993 159.605C205.453 159.656 206.107 159.682 206.956 159.682C207.293 159.682 207.738 159.682 208.29 159.682C208.842 159.682 209.307 159.661 209.686 159.621C210.074 159.58 210.473 159.508 210.882 159.406C211.301 159.293 211.598 159.145 211.772 158.961C212.242 158.47 212.733 157.729 213.244 156.737C213.765 155.735 214.098 154.999 214.241 154.529H215.345Z" fill="#3155A4"/>
    <path d="M238.412 150.188C238.412 152.039 238.065 153.644 237.369 155.004C236.674 156.364 235.769 157.478 234.655 158.348C233.53 159.217 232.267 159.866 230.866 160.295C229.466 160.725 228.019 160.939 226.526 160.939H217.508V159.851C217.804 159.851 218.188 159.825 218.658 159.774C219.138 159.713 219.455 159.646 219.609 159.574C219.916 159.442 220.135 159.247 220.268 158.992C220.411 158.726 220.483 158.409 220.483 158.041V142.336C220.483 141.988 220.422 141.676 220.299 141.4C220.187 141.124 219.956 140.904 219.609 140.74C219.292 140.587 218.949 140.475 218.581 140.403C218.213 140.331 217.896 140.285 217.63 140.265V139.176H227.139C228.448 139.176 229.736 139.381 231.004 139.79C232.272 140.188 233.376 140.73 234.317 141.415C235.595 142.325 236.597 143.516 237.323 144.989C238.049 146.461 238.412 148.194 238.412 150.188ZM234.655 150.173C234.655 148.711 234.46 147.392 234.072 146.216C233.683 145.03 233.116 143.997 232.369 143.118C231.664 142.279 230.795 141.62 229.762 141.139C228.74 140.659 227.599 140.418 226.342 140.418C225.923 140.418 225.447 140.429 224.915 140.449C224.394 140.459 224.005 140.47 223.75 140.48V157.213C223.75 158.143 224.021 158.787 224.563 159.145C225.105 159.503 225.938 159.682 227.063 159.682C228.361 159.682 229.496 159.452 230.468 158.992C231.439 158.532 232.226 157.893 232.829 157.075C233.463 156.216 233.924 155.224 234.21 154.099C234.506 152.964 234.655 151.655 234.655 150.173Z" fill="#3155A4"/>
    <path d="M250.605 160.939H240.805V159.851C241.081 159.83 241.48 159.799 242.001 159.759C242.523 159.718 242.891 159.656 243.105 159.574C243.443 159.442 243.688 159.263 243.842 159.038C243.995 158.803 244.072 158.48 244.072 158.071V142.305C244.072 141.947 244.015 141.65 243.903 141.415C243.79 141.17 243.525 140.965 243.105 140.802C242.799 140.679 242.41 140.567 241.94 140.464C241.48 140.352 241.101 140.285 240.805 140.265V139.176H250.605V140.265C250.288 140.275 249.91 140.316 249.47 140.388C249.031 140.449 248.647 140.531 248.32 140.633C247.931 140.756 247.671 140.955 247.538 141.231C247.405 141.507 247.338 141.814 247.338 142.151V157.903C247.338 158.24 247.41 158.542 247.553 158.808C247.707 159.063 247.962 159.268 248.32 159.421C248.535 159.503 248.882 159.595 249.363 159.697C249.854 159.789 250.268 159.84 250.605 159.851V160.939Z" fill="#3155A4"/>
    <path d="M271.019 145.081H269.961C269.869 144.713 269.7 144.278 269.455 143.777C269.219 143.266 268.954 142.785 268.657 142.336C268.35 141.865 268.013 141.456 267.645 141.109C267.287 140.751 266.939 140.546 266.602 140.495C266.285 140.454 265.886 140.423 265.406 140.403C264.925 140.383 264.48 140.372 264.071 140.372H263.182V157.78C263.182 158.117 263.253 158.429 263.396 158.716C263.54 158.992 263.79 159.206 264.148 159.36C264.332 159.431 264.715 159.528 265.298 159.651C265.891 159.774 266.351 159.84 266.679 159.851V160.939H256.403V159.851C256.689 159.83 257.124 159.789 257.706 159.728C258.299 159.667 258.708 159.59 258.933 159.498C259.271 159.365 259.516 159.176 259.669 158.93C259.833 158.675 259.915 158.337 259.915 157.918V140.372H259.025C258.708 140.372 258.315 140.383 257.844 140.403C257.374 140.413 256.924 140.444 256.495 140.495C256.157 140.536 255.805 140.74 255.436 141.109C255.079 141.466 254.746 141.875 254.44 142.336C254.133 142.796 253.862 143.286 253.627 143.808C253.391 144.319 253.228 144.743 253.136 145.081H252.062V139.176H271.019V145.081Z" fill="#3155A4"/>
    <path d="M317.141 137.109C316.821 137.121 316.352 137.189 315.734 137.315C315.117 137.441 314.614 137.629 314.225 137.881C313.836 138.144 313.568 138.687 313.419 139.51C313.271 140.333 313.196 141.34 313.196 142.529V153.11C313.196 154.459 312.916 155.631 312.356 156.626C311.796 157.62 311.058 158.438 310.144 159.078C309.24 159.696 308.286 160.136 307.28 160.399C306.285 160.673 305.33 160.81 304.416 160.81C302.952 160.81 301.649 160.616 300.505 160.227C299.362 159.85 298.402 159.335 297.624 158.684C296.858 158.032 296.281 157.289 295.892 156.454C295.503 155.608 295.309 154.728 295.309 153.813V139.424C295.309 139.024 295.24 138.693 295.103 138.43C294.977 138.155 294.714 137.904 294.314 137.675C294.028 137.515 293.663 137.389 293.217 137.298C292.771 137.195 292.405 137.132 292.119 137.109V135.892H302.255V137.109C301.934 137.121 301.529 137.172 301.037 137.264C300.557 137.355 300.202 137.441 299.974 137.521C299.585 137.658 299.322 137.887 299.185 138.207C299.059 138.527 298.996 138.876 298.996 139.253V152.698C298.996 153.35 299.065 154.036 299.202 154.756C299.351 155.465 299.636 156.128 300.059 156.746C300.505 157.375 301.111 157.889 301.877 158.289C302.643 158.689 303.695 158.89 305.033 158.89C306.291 158.89 307.342 158.689 308.188 158.289C309.046 157.889 309.721 157.363 310.212 156.711C310.681 156.094 311.012 155.448 311.207 154.774C311.401 154.099 311.498 153.419 311.498 152.733V142.752C311.498 141.482 311.407 140.448 311.224 139.647C311.041 138.836 310.772 138.304 310.418 138.053C310.018 137.767 309.469 137.544 308.772 137.384C308.086 137.224 307.56 137.132 307.194 137.109V135.892H317.141V137.109Z" fill="#3155A4"/>
    <path d="M343.954 140.265C343.668 140.275 343.228 140.357 342.635 140.51C342.042 140.664 341.618 140.822 341.362 140.986C340.994 141.241 340.743 141.732 340.61 142.458C340.488 143.184 340.426 144.104 340.426 145.219V161.154H339.092L325.902 143.348V154.575C325.902 155.832 325.979 156.798 326.132 157.473C326.296 158.148 326.541 158.629 326.868 158.915C327.104 159.14 327.589 159.35 328.325 159.544C329.062 159.738 329.547 159.84 329.782 159.851V160.939H320.856V159.851C321.132 159.83 321.598 159.733 322.252 159.559C322.906 159.375 323.315 159.212 323.479 159.068C323.837 158.782 324.082 158.348 324.215 157.765C324.348 157.172 324.414 156.165 324.414 154.743V144.023C324.414 143.552 324.333 143.082 324.169 142.612C324.005 142.141 323.786 141.778 323.51 141.523C323.141 141.185 322.64 140.899 322.006 140.664C321.383 140.418 320.877 140.285 320.488 140.265V139.176H326.7L338.939 155.771V145.418C338.939 144.15 338.857 143.179 338.693 142.504C338.53 141.819 338.295 141.364 337.988 141.139C337.702 140.945 337.231 140.756 336.577 140.572C335.933 140.388 335.442 140.285 335.104 140.265V139.176H343.954V140.265Z" fill="#3155A4"/>
    <path d="M355.242 160.939H345.442V159.851C345.718 159.83 346.117 159.799 346.638 159.759C347.159 159.718 347.528 159.656 347.742 159.574C348.08 159.442 348.325 159.263 348.478 159.038C348.632 158.803 348.708 158.48 348.708 158.071V142.305C348.708 141.947 348.652 141.65 348.54 141.415C348.427 141.17 348.161 140.965 347.742 140.802C347.435 140.679 347.047 140.567 346.577 140.464C346.117 140.352 345.738 140.285 345.442 140.265V139.176H355.242V140.265C354.925 140.275 354.547 140.316 354.107 140.388C353.667 140.449 353.284 140.531 352.957 140.633C352.568 140.756 352.308 140.955 352.175 141.231C352.042 141.507 351.975 141.814 351.975 142.151V157.903C351.975 158.24 352.047 158.542 352.19 158.808C352.343 159.063 352.599 159.268 352.957 159.421C353.172 159.503 353.519 159.595 354 159.697C354.491 159.789 354.905 159.84 355.242 159.851V160.939Z" fill="#3155A4"/>
    <path d="M375.549 141.753C376.51 142.755 377.261 143.966 377.803 145.388C378.345 146.799 378.616 148.363 378.616 150.081C378.616 151.809 378.34 153.378 377.788 154.789C377.236 156.2 376.469 157.402 375.487 158.394C374.536 159.375 373.432 160.132 372.174 160.663C370.917 161.195 369.577 161.461 368.156 161.461C366.643 161.461 365.242 161.18 363.954 160.617C362.676 160.045 361.576 159.252 360.656 158.24C359.736 157.248 359.01 156.047 358.478 154.636C357.957 153.215 357.696 151.696 357.696 150.081C357.696 148.312 357.972 146.737 358.524 145.357C359.076 143.966 359.838 142.765 360.81 141.753C361.771 140.751 362.885 139.984 364.153 139.452C365.431 138.92 366.765 138.655 368.156 138.655C369.598 138.655 370.958 138.926 372.236 139.467C373.514 140.009 374.618 140.771 375.549 141.753ZM373.263 157.182C373.836 156.251 374.25 155.198 374.506 154.022C374.771 152.847 374.904 151.533 374.904 150.081C374.904 148.588 374.756 147.218 374.46 145.97C374.173 144.723 373.749 143.66 373.187 142.78C372.624 141.911 371.919 141.236 371.07 140.756C370.221 140.265 369.25 140.02 368.156 140.02C366.929 140.02 365.886 140.301 365.027 140.863C364.168 141.426 363.468 142.177 362.926 143.118C362.415 144.017 362.031 145.071 361.776 146.277C361.53 147.484 361.408 148.752 361.408 150.081C361.408 151.553 361.541 152.882 361.806 154.068C362.072 155.255 362.491 156.308 363.064 157.228C363.616 158.128 364.311 158.833 365.15 159.344C365.988 159.845 366.99 160.096 368.156 160.096C369.25 160.096 370.237 159.84 371.116 159.329C371.995 158.818 372.711 158.102 373.263 157.182Z" fill="#3155A4"/>
    <path d="M403.677 140.265C403.391 140.275 402.951 140.357 402.358 140.51C401.765 140.664 401.34 140.822 401.085 140.986C400.717 141.241 400.466 141.732 400.333 142.458C400.211 143.184 400.149 144.104 400.149 145.219V161.154H398.815L385.625 143.348V154.575C385.625 155.832 385.702 156.798 385.855 157.473C386.019 158.148 386.264 158.629 386.591 158.915C386.826 159.14 387.312 159.35 388.048 159.544C388.784 159.738 389.27 159.84 389.505 159.851V160.939H380.579V159.851C380.855 159.83 381.32 159.733 381.975 159.559C382.629 159.375 383.038 159.212 383.202 159.068C383.56 158.782 383.805 158.348 383.938 157.765C384.071 157.172 384.137 156.165 384.137 154.743V144.023C384.137 143.552 384.056 143.082 383.892 142.612C383.728 142.141 383.508 141.778 383.232 141.523C382.864 141.185 382.363 140.899 381.729 140.664C381.106 140.418 380.6 140.285 380.211 140.265V139.176H386.423L398.662 155.771V145.418C398.662 144.15 398.58 143.179 398.416 142.504C398.253 141.819 398.017 141.364 397.711 141.139C397.424 140.945 396.954 140.756 396.3 140.572C395.656 140.388 395.165 140.285 394.827 140.265V139.176H403.677V140.265Z" fill="#3155A4"/>
  </svg>
);

export default Logo;
