import React from 'react';

const Wallet = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#f37c7e" d="M114.4,68.6V50.3c0-7.6-6.2-13.8-13.801-13.8H22.8C15.2,36.5,9,30.3,9,22.8v82.5 c0,7.601,6.2,13.8,13.8,13.8h77.9c7.6,0,13.8-6.199,13.8-13.8V86.9L114.4,68.6"/><path fill="#dc7173" d="M9,22.8v82.5c0,7.601,6.2,13.8,13.8,13.8V36.5C15.2,36.5,9,30.3,9,22.8"/><path fill="#f7f7fb" d="M104.4,22.8c0-7.6-6.2-13.8-13.8-13.8H22.8C14.9,9,8.6,15.5,9,23.4c0.3,7.4,6.7,13.1,14.1,13.1h81.15 L104.4,22.8z"/><path fill="#f8f4b2" d="M18.2,35.6c1.6,0.6,3.2,0.9,5,0.9h82.5c1.6,0,3.1,0.3,4.6,0.8v-0.8c0-7.6-6.1-13.7-13.7-13.7H31.1 C23.9,22.8,18.2,28.5,18.2,35.6z"/><path fill="#f7f7fb" d="M105.4,86.9c-5.301,0-9.5-4.4-9.101-9.801c0.3-4.899,4.601-8.6,9.5-8.6h11.5c1,0,1.8,0.8,1.8,1.8V85 c0,1-0.8,1.8-1.8,1.8L105.4,86.9z"/><path fill="#464c55" d="M117.5,65.6V50.4c0-4.4-1.7-8.3-4.4-11.3v-2.6c0-4.5-1.699-8.6-4.899-11.8c-0.3-0.3-0.601-0.6-1-0.9 c0.1-0.3,0.2-0.6,0.2-1C107.4,13.5,99.8,6,90.6,6H22.7c-4.6,0-8.9,1.9-12.1,5.2C7.6,14.4,6,18.5,6,22.8v82.4 c0,9.3,7.5,16.8,16.8,16.8h77.9c9.2,0,16.7-7.5,16.8-16.8V89.9c2.5-0.2,4.5-2.3,4.5-4.8V70.4C122,67.9,120.1,65.8,117.5,65.6z M116,71.6V84h-10.6c-1.7,0-3.301-0.7-4.5-2c-1.2-1.2-1.801-2.9-1.7-4.6c0.2-3.301,3.1-5.801,6.5-5.801L116,71.6L116,71.6z M104,28.9c1.4,1.4,2.3,2.6,2.8,4.4H21.4c1.1-4.3,5-7.5,9.5-7.5h65.5C99.3,25.8,102,26.9,104,28.9z M15,15.3 c2.1-2.1,4.8-3.3,7.8-3.3h67.8c5.2,0,9.5,3.7,10.5,8.5c-1.5-0.4-3.1-0.7-4.699-0.7H30.9c-7,0-13,4.6-15,10.9 c-2.1-1.8-3.6-4.3-3.8-7.1c0-0.2,0-0.5,0-0.8C12,20.1,13.1,17.3,15,15.3z M100.7,116.1H22.8c-6,0-10.8-4.899-10.8-10.8V35.6 c3,2.4,6.7,3.9,10.8,3.9h0.3h77.6c6,0,10.8,4.9,10.8,10.8v15.3h-5.8c-6.601,0-12.101,5-12.5,11.4c-0.2,3.4,1,6.6,3.3,9.1 c2.3,2.4,5.5,3.801,8.9,3.801h6.1V105.3C111.5,111.3,106.6,116.1,100.7,116.1z"/></svg>
  );
};

Wallet.defaultProps = {
  width: 30
};

export default Wallet;
