import React from 'react';

const ApplePay = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#444b54" d="M39.9 55.6c-5.7 4-5.9 11.9 1 15.7-1.2 3.5-3 6.6-5.7 9.1-1.6 1.4-3.5 1.6-5.4.7-2.9-1.3-5.6-1.3-8.5 0-1.8.9-3.7.7-5.2-.7-5.1-4.5-8.7-14.1-6.7-21 1.3-4.6 5.1-7.6 9.5-7.5.9 0 1.9.2 2.7.6 2.4 1.2 4.8 1.1 7.2 0 2.1-1 4.4-1 6.7-.2C37.3 52.9 38.8 53.9 39.9 55.6zM24.9 51.2c-.4-4.3 3.2-8.5 7.7-8.9C33.6 45.7 29.7 51.6 24.9 51.2zM61.1 40.7h-10c-1.7 0-3 1.3-3 3v35c0 1.7 1.3 3 3 3s3-1.3 3-3v-12h7c7.2 0 13-5.8 13-13S68.3 40.7 61.1 40.7zM61.1 60.7h-7v-14h7c3.9 0 7 3.1 7 7S65 60.7 61.1 60.7zM120 50.9c-1.6-.5-3.3.4-3.8 2l-5.6 17.9-5.6-18c-.5-1.6-2.2-2.5-3.8-2-1.6.5-2.5 2.2-2 3.8l8.2 26.1-.5 1.7c-.6 1.9-2.4 3.2-4.4 3.2h-.4c-1.7 0-3 1.3-3 3s1.3 3 3 3h.4c4.7 0 8.7-3 10.1-7.4l9.3-29.7C122.5 53.1 121.6 51.4 120 50.9zM86.4 50.7h-.7c-2.3 0-4.6.8-6.4 2.1l-1.9 1.5c-1.3 1-1.6 2.9-.6 4.2s2.9 1.6 4.2.6l1.9-1.5c.8-.6 1.8-.9 2.8-.9h.7c1.4 0 2.8.6 3.7 1.8.5.6.9 1.4 1 2.2h-6.4c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h2c1.8 0 3.5-.5 5-1.3.5.8 1.4 1.3 2.5 1.3 1.7 0 3-1.3 3-3v-7.5-2.5-5-2.3c0-2.4-.8-4.8-2.3-6.7C92.8 52.2 89.7 50.7 86.4 50.7zM86.6 75.7h-2c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5h6.5v2 2.5C91.1 73.7 89.1 75.7 86.6 75.7z"/></svg>
  );
};

ApplePay.defaultProps = {
  width: 30
};

export default ApplePay;
