import React from 'react';

const SafeOk = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#9eb9d3" d="M109,113.3H39c-5.5,0-10-4.5-10-10v-70c0-5.5,4.5-10,10-10h70c5.5,0,10,4.5,10,10v70C119,108.9,114.5,113.3,109,113.3z"/><path fill="#fff" d="M99,113.3H29c-5.5,0-10-4.5-10-10v-70c0-5.5,4.5-10,10-10h70c5.5,0,10,4.5,10,10v70C109,108.9,104.5,113.3,99,113.3z"/><path fill="#fff" d="M32.2 36.6H95.7V100.1H32.2z" transform="rotate(90 64 68.33)"/><path fill="#444b54" d="M95.8 103.1H32.2c-1.7 0-3-1.3-3-3V36.6c0-1.7 1.3-3 3-3h63.5c1.7 0 3 1.3 3 3v63.5C98.8 101.8 97.4 103.1 95.8 103.1zM35.2 97.1h57.5V39.6H35.2V97.1zM32.2 122c-1.7 0-3-1.3-3-3v-5.7c0-1.7 1.3-3 3-3s3 1.3 3 3v5.7C35.2 120.7 33.9 122 32.2 122zM95.5 122c-1.7 0-3-1.3-3-3v-5.7c0-1.7 1.3-3 3-3s3 1.3 3 3v5.7C98.5 120.7 97.2 122 95.5 122z"/><path fill="#444b54" d="M100.5 49H91c-1.7 0-3-1.3-3-3s1.3-3 3-3h9.5c1.7 0 3 1.3 3 3S102.2 49 100.5 49zM100.5 93.4H91c-1.7 0-3-1.3-3-3s1.3-3 3-3h9.5c1.7 0 3 1.3 3 3S102.2 93.4 100.5 93.4z"/><g><path fill="#444b54" d="M99,116.3H29c-7.2,0-13-5.8-13-13v-70c0-7.2,5.8-13,13-13h70c7.2,0,13,5.8,13,13v70C112,110.5,106.2,116.3,99,116.3z M29,26.3c-3.9,0-7,3.1-7,7v70c0,3.9,3.1,7,7,7h70c3.9,0,7-3.1,7-7v-70c0-3.9-3.1-7-7-7H29z"/></g><g><path fill="#60ccaa" d="M64,79c-1,0-1.9-0.5-2.5-1.3l-6-9c-0.9-1.4-0.5-3.2,0.8-4.2c1.4-0.9,3.2-0.5,4.2,0.8l3.3,5l7.6-12.9c0.8-1.4,2.7-1.9,4.1-1.1c1.4,0.8,1.9,2.7,1.1,4.1l-10,17C66.1,78.4,65.1,79,64,79C64.1,79,64,79,64,79z"/></g></svg>
  );
};

SafeOk.defaultProps = {
  width: 30
};

export default SafeOk;
