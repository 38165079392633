import React from 'react';

const UserGroups = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M68.4 106C62 94.8 49.9 87.2 36 87.2h0c-13.9 0-26 7.6-32.4 18.9l.1 3h64.6L68.4 106zM36 40A17.5 17.5 0 1 0 36 75 17.5 17.5 0 1 0 36 40z"/><path fill="#71c2ff" d="M36 77.9c-11.3 0-20.5-9.2-20.5-20.5 0-10.7 8.4-19.7 19.1-20.4 1.7-.1 3.1 1.1 3.2 2.8.1 1.7-1.1 3.1-2.8 3.2-7.6.5-13.5 6.8-13.5 14.4 0 8 6.5 14.5 14.5 14.5 2.6 0 5.2-.7 7.4-2 1.4-.8 3.3-.4 4.1 1 .8 1.4.4 3.3-1 4.1C43.3 76.9 39.7 77.9 36 77.9zM3.6 109c-.5 0-1-.1-1.5-.4-1.4-.8-1.9-2.6-1.1-4.1 5.2-9.2 14-16.1 24.2-18.9 1.6-.4 3.3.5 3.7 2.1s-.5 3.3-2.1 3.7c-8.6 2.4-16.1 8.3-20.6 16.1C5.7 108.5 4.7 109 3.6 109z"/><g><path fill="#fff" d="M59.6 106C66 94.8 78.1 87.2 92 87.2h0c13.9 0 26 7.6 32.4 18.9l-.1 3H59.7L59.6 106zM92 40A17.5 17.5 0 1 0 92 75 17.5 17.5 0 1 0 92 40z"/><path fill="#71c2ff" d="M92 77.9c-3.7 0-7.3-1-10.4-2.9-1.4-.8-1.9-2.7-1-4.1.8-1.4 2.7-1.9 4.1-1 2.2 1.3 4.8 2 7.4 2 8 0 14.5-6.5 14.5-14.5 0-7.6-5.9-13.9-13.5-14.4-1.7-.1-2.9-1.5-2.8-3.2.1-1.7 1.5-2.9 3.2-2.8 10.7.7 19.1 9.7 19.1 20.4C112.5 68.8 103.3 77.9 92 77.9zM124.4 109c-1 0-2.1-.5-2.6-1.5-4.4-7.8-11.9-13.7-20.6-16.1-1.6-.4-2.5-2.1-2.1-3.7.4-1.6 2.1-2.5 3.7-2.1 10.1 2.8 18.9 9.7 24.2 18.9.8 1.4.3 3.3-1.1 4.1C125.4 108.9 124.9 109 124.4 109z"/></g><g><path fill="#fff" d="M101.1 105.6C93.7 92.7 79.9 84 64 84h0c-15.9 0-29.7 8.7-37.1 21.6L27 109h74L101.1 105.6zM64 30A20 20 0 1 0 64 70 20 20 0 1 0 64 30z"/><path fill="#444b54" d="M64 73c-12.7 0-23-10.3-23-23s10.3-23 23-23 23 10.3 23 23S76.7 73 64 73zM64 33c-9.4 0-17 7.6-17 17s7.6 17 17 17c9.4 0 17-7.6 17-17S73.4 33 64 33zM26.9 109c-.5 0-1-.1-1.5-.4-1.4-.8-1.9-2.6-1.1-4.1C32.4 90.3 47.6 81.4 64 81.4c16.4 0 31.6 8.9 39.7 23.1.8 1.4.3 3.3-1.1 4.1s-3.3.3-4.1-1.1c-7-12.4-20.2-20.1-34.5-20.1-14.2 0-27.4 7.7-34.5 20.1C29 108.5 28 109 26.9 109z"/></g></svg>
  );
};

UserGroups.defaultProps = {
  width: 30
};

export default UserGroups;
