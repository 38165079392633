import React from 'react';
import ReactDOM from 'react-dom';

class Portal extends React.Component {
  render() {
    const { container, children } = this.props;

    if (!container) {
      return null;
    }

    return ReactDOM.createPortal(children, container);
  }
}

export default Portal;
