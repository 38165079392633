import React from 'react';

const Profit = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#9c93d1" d="M104,121H24c-5.5,0-10-4.5-10-10V67c0-5.5,4.5-10,10-10h80c5.5,0,10,4.5,10,10v44 C114,116.5,109.5,121,104,121"/><path fill="#d7d4ed" d="M64,104c-8.3,0-15-6.7-15-15s6.7-15,15-15s15,6.7,15,15S72.3,104,64,104 M94,67H34 c0,5.5-4.5,10-10,10v24c5.5,0,10,4.5,10,10h60c0-5.5,4.5-10,10-10V77C98.5,77,94,72.5,94,67"/><path fill="#f3f2fa" d="M64,74c-8.3,0-15,6.7-15,15s6.7,15,15,15s15-6.7,15-15S72.3,74,64,74"/><path fill="#444b54" d="M104 124H24c-7.2 0-13-5.8-13-13V67c0-7.2 5.8-13 13-13h80c7.2 0 13 5.8 13 13v44C117 118.2 111.2 124 104 124zM24 60c-3.9 0-7 3.1-7 7v44c0 3.9 3.1 7 7 7h80c3.9 0 7-3.1 7-7V67c0-3.9-3.1-7-7-7H24zM114.4 6.5c0-.1 0-.1 0-.2s-.1-.2-.1-.4c0-.1 0-.1-.1-.2-.1-.2-.1-.3-.2-.5l0 0c-.1-.1-.2-.2-.3-.4l-.1-.1c-.1-.1-.2-.2-.3-.2l-.1-.1c-.1-.1-.2-.1-.4-.2h-.1l0 0c-.2-.1-.3-.1-.5-.2h-.1c-.1 0-.3 0-.4 0h-.1-10c-1.7 0-3 1.3-3 3s1.3 3 3 3h2.8L76.5 37.8 53.6 14.9c-1.2-1.2-3.1-1.2-4.2 0l-15 15c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l12.9-12.9 22.9 22.9c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l29.9-29.9V17c0 1.7 1.3 3 3 3s3-1.3 3-3V7C114.5 6.8 114.5 6.6 114.4 6.5zM29.3 40.8c-.1-.2-.2-.3-.3-.5-.1-.2-.2-.3-.4-.4-.1-.1-.3-.3-.4-.4-.2-.1-.3-.2-.5-.3s-.4-.1-.6-.2c-1-.2-2 .1-2.7.8-.1.1-.3.3-.4.4-.1.2-.2.3-.3.5-.1.2-.1.4-.2.6 0 .2-.1.4-.1.6 0 .2 0 .4.1.6 0 .2.1.4.2.6.1.2.2.4.3.5.1.2.2.3.4.4.6.6 1.3.9 2.1.9.2 0 .4 0 .6-.1.2 0 .4-.1.6-.2.2-.1.4-.2.5-.3.2-.1.3-.2.4-.4.1-.1.3-.3.4-.4.1-.2.2-.3.3-.5.1-.2.1-.4.2-.6 0-.2.1-.4.1-.6 0-.2 0-.4-.1-.6C29.4 41.2 29.3 41 29.3 40.8z"/></svg>
  );
};

Profit.defaultProps = {
  width: 30
};

export default Profit;
