import React from 'react';

const Location = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M64 9.099999999999994A55 55 0 1 0 64 119.1A55 55 0 1 0 64 9.099999999999994Z" transform="rotate(-65.001 64.012 64.075)"/><path fill="#adf9d2" d="M74.8 73c-2.8 3.9 11.9 15.8 16.8 13.5 8.3-3.9 13.7-12 13.7-15s-3.9-8.8-17.2.2C84.4 74.3 77.7 69 74.8 73zM82.6 12.2c-.1 0-.2-.1-.2-.1l.2 10.6c0 2.7-1.6 5.2-4.1 6.3-5.5 2.4-11 1.1-13.3 7.4C63.3 41.1 68.3 54 74.4 54c7.7 0 11.4-15.5 13.7-15.5 1.5 0 3.8 4.7 6.5 8.2 1.8 2.4 4.6 3.7 7.6 3.7h5.1C107.3 50.4 100.4 18.6 82.6 12.2zM27.7 105.2C27.7 105.3 27.7 105.3 27.7 105.2c3.9 3.4 9.5 3.9 13.9 1.4 1.8-1 3.7-2.2 5.7-3.6 1.4-1 2.6-2.3 3.5-3.8 1-1.8 2.2-3.7 2.8-5.9 3.9-15-9.1-15.6-9.5-18.9-.4-3.7 4.4-6.5 7.3-9.4 2.9-2.9 3.8-7.4 2.3-10-4.8-8.6-16.2-4.1-17.3 5.1-.6 5.5-3.9 11.1-6.7 14-2.8 2.8-8.3 1.3-9.8-2.8-1.7-4.6 4.2-6.3 3.3-12.2-.2-1.6-1.9-2.4-4-2.7l-9.9.4C9.2 59.1 9 61.5 9 64c0 12.3 4 23.6 10.9 32.8C19.9 96.8 27.7 104.2 27.7 105.2z"/><path fill="#444b54" d="M64,122.1c-8.4,0-16.7-1.8-24.5-5.5c-29-13.5-41.6-48.1-28-77C20,21.3,37,9,57,6.5c1.7-0.2,3.1,1,3.3,2.6c0.2,1.6-1,3.1-2.6,3.3c-17.9,2.2-33.2,13.3-40.8,29.6c-12.1,26-0.8,57,25.1,69.1c12.6,5.9,26.7,6.5,39.7,1.7c13-4.7,23.5-14.3,29.3-26.9c2.5-5.4,4.1-11.1,4.6-16.9c0.2-1.7,1.6-2.9,3.3-2.7c1.6,0.2,2.9,1.6,2.7,3.3c-0.6,6.5-2.4,12.9-5.2,18.8c-6.5,14-18.2,24.7-32.7,30C77.4,120.9,70.7,122.1,64,122.1z"/><path fill="#444b54" d="M89,2C73.8,2,61.5,14.3,61,29.9c-0.4,14.5,13.5,33.6,21.8,43.6c1.5,1.8,3.8,2.9,6.2,2.9s4.6-1.1,6.2-2.9c8.3-10.1,22.3-29.1,21.8-43.6C116.5,14.3,104.2,2,89,2z"/><path fill="#fff" d="M89 20A10 10 0 1 0 89 40A10 10 0 1 0 89 20Z"/></svg>
  );
};

Location.defaultProps = {
  width: 30
};

export default Location;
