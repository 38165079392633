import React from 'react';

const EmailOpen = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M114,49V24c0-2.8-2.2-5-5-5H24c-2.8,0-5,2.2-5,5v25l47.5,40L114,49z"/><path fill="none" stroke="#444b54" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="6" d="M114,49V24c0-2.8-2.2-5-5-5H44H24c-2.8,0-5,2.2-5,5v5v9"/><path fill="#fff0b3" d="M109,114H24c-2.8,0-5-2.2-5-5V49l47.5,40L114,49v60C114,111.8,111.8,114,109,114z"/><path fill="#f2b630" d="M86.1 72.5L114 110.9 77.7 79.6z"/><path fill="#fff0b3" d="M33,100l26.6-24.7c3.9-3.6,9.8-3.5,13.7,0.1l37.6,35.5"/><path fill="#f2b630" d="M114,109l-10-5H44c-2.8,0-5,2.2-5,5v0c0,2.8,2.2,5,5,5h65C111.8,114,114,111.8,114,109z"/><path fill="none" stroke="#444b54" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="6" d="M33,100l26.6-24.7c3.9-3.6,9.8-3.5,13.7,0.1l37.6,35.5"/><path fill="none" stroke="#444b54" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="6" d="M77.7,79.6L114,49v60c0,2.8-2.2,5-5,5H24c-2.8,0-5-2.2-5-5V49l28.8,24.3"/></svg>
  );
};

EmailOpen.defaultProps = {
  width: 30
};

export default EmailOpen;
