import React from 'react';

const Minus = () => {
  return (
    <div
      className="bg-periwinkle-base absolute"
      style={{
        left: 0,
        right: 0,
        top: '50%',
        height: 2,
        transform: 'translateY(-50%)'
      }}
    />
  );
};

const Plus = () => {
  return (
    <React.Fragment>
      <div
        className="bg-periwinkle-base absolute"
        style={{
          width: 2,
          bottom: 0,
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      />
      <Minus />
    </React.Fragment>
  )
};

const BASE_BUTTON_CLASS = `
  text-2xl flex items-center focus:outline-none text-left w-full pb-2 border-b border-gray-100 mb-4 font-semibold text-gray-900
`;

const Accordion = ({ title, children, openByDefault, className, containerClassName }) => {
  const [expanded, setExpanded] = React.useState(openByDefault);
  const [maxHeight, setMaxHeight] = React.useState(null);
  const contentRef = React.useRef();

  React.useEffect(() => {
    if (contentRef && contentRef.current) {
      setMaxHeight(5000);
    }
  }, [setMaxHeight]);

  return (
    <div className="my-8">
      <button
        onClick={() => setExpanded(!expanded)}
        className={className || BASE_BUTTON_CLASS}
      >
        <div
          className="mt-1 mr-3 ml-2 relative"
          style={{ width: '14px', height: '14px' }}
        >
          {expanded ? (<Minus />) : (<Plus />)}
        </div>
        {title}
      </button>
      <div
        style={{
          maxHeight: expanded ? maxHeight : 0,
          overflow: 'hidden',
          transition: 'max-height',
          transitionTimingFunction: 'ease-in-out',
          transitionDuration: '200ms'
        }}
      >
        <div className={containerClassName} ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
};


Accordion.defaultProps = {
  containerClassName: 'ml-9'
};

export default Accordion;
