import React from 'react';

const InsertCard = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#a7b1c5" d="M109.5,34h-91C13.3,34,9,29.7,9,24.5c0-5.2,4.3-9.5,9.5-9.5h91c5.2,0,9.5,4.3,9.5,9.5 C119,29.7,114.7,34,109.5,34"/><path fill="#454b54" d="M109.5,34v-3h-91c-1.8,0-3.4-0.7-4.6-1.9c-1.2-1.2-1.9-2.8-1.9-4.6c0-1.8,0.7-3.4,1.9-4.6 c1.2-1.2,2.8-1.9,4.6-1.9h91c1.8,0,3.4,0.7,4.6,1.9c1.2,1.2,1.9,2.8,1.9,4.6c0,1.8-0.7,3.4-1.9,4.6c-1.2,1.2-2.8,1.9-4.6,1.9V34v3 c3.4,0,6.6-1.4,8.8-3.7c2.3-2.3,3.7-5.4,3.7-8.8c0-3.4-1.4-6.6-3.7-8.8c-2.3-2.3-5.4-3.7-8.8-3.7h-91c-3.4,0-6.6,1.4-8.8,3.7 C7.4,17.9,6,21.1,6,24.5c0,3.4,1.4,6.6,3.7,8.8c2.3,2.3,5.4,3.7,8.8,3.7h91V34"/><path fill="#868e9e" d="M109.5 18H104v13h5.5c.2 0 .3 0 .5 0V18C109.8 18 109.7 18 109.5 18M24 18h-5.5c-.2 0-.3 0-.5 0v13c.2 0 .3 0 .5 0H24V18"/><path fill="#addff8" stroke="#454b54" stroke-miterlimit="10" stroke-width="6" d="M101,15v88c0,5.5-4.5,10-10,10H37 c-5.5,0-10-4.5-10-10V15H101z"/><path fill="#454b54" d="M87,68v28c0,1.7-1.3,3-3,3l0,0c-1.7,0-3-1.3-3-3V68c0-1.7,1.3-3,3-3l0,0C85.7,65,87,66.3,87,68z"/><path fill="#fff" d="M53,18v41c0,1.7-1.3,3-3,3l0,0c-1.7,0-3-1.3-3-3V18H53z"/><path fill="#454b54" d="M76 17v14c0 1.7-1.3 3-3 3l0 0c-1.7 0-3-1.3-3-3V17H76zM76 41v12c0 1.7-1.3 3-3 3l0 0c-1.7 0-3-1.3-3-3V41c0-1.7 1.3-3 3-3l0 0C74.7 38 76 39.3 76 41zM76 63v12c0 1.7-1.3 3-3 3l0 0c-1.7 0-3-1.3-3-3V63c0-1.7 1.3-3 3-3l0 0C74.7 60 76 61.3 76 63zM76 85v11c0 1.7-1.3 3-3 3l0 0c-1.7 0-3-1.3-3-3V85c0-1.7 1.3-3 3-3l0 0C74.7 82 76 83.3 76 85z"/><path fill="#f7f3b1" d="M60,81v13c0,2.8-2.2,5-5,5h-9c-2.8,0-5-2.2-5-5V81c0-2.8,2.2-5,5-5h9C57.8,76,60,78.2,60,81z"/></svg>
  );
};

InsertCard.defaultProps = {
  width: 30
};

export default InsertCard;
