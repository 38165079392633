import React from 'react';

const DollarPlaceMarker = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#EDECEB" d="M64 9A55 55 0 1 0 64 119A55 55 0 1 0 64 9Z"/><path fill="#FFF" d="M64,25c-19.7,0-35.8,16-35.9,35.6c0,7.4,2.3,14.6,6.6,20.7c0.1,0.1,0.1,0.2,0.2,0.2l0.1,0.1l27.5,36.6c0.4,0.5,0.9,0.8,1.5,0.8c0.6,0,1.1-0.3,1.5-0.8l27.6-36.8c0.1,0,0.2-0.1,0.2-0.2c4.3-6.1,6.6-13.2,6.6-20.6C99.8,41,83.8,25,64,25z"/><path fill="#454B54" d="M64,122c-1.4,0-2.8-0.7-3.8-1.8c0,0-0.1-0.1-0.1-0.1L32.8,83.6c-0.2-0.2-0.3-0.4-0.4-0.6c-4.7-6.6-7.1-14.4-7.1-22.4C25.3,39.3,42.7,22,64,22c21.4,0,38.8,17.4,38.8,38.7c0,7.9-2.4,15.5-6.9,22.1c-0.2,0.3-0.4,0.6-0.7,0.9L67.9,120c0,0-0.1,0.1-0.1,0.1C66.9,121.3,65.5,122,64,122z M37.3,79.7c0,0,0.1,0.1,0.1,0.1L64,115.3l26.7-35.6c0.1-0.1,0.2-0.2,0.2-0.3c3.8-5.5,5.9-12,5.9-18.7C96.8,42.7,82.1,28,64,28c-18,0-32.8,14.6-32.9,32.7c0,6.7,2.1,13.2,6,18.8C37.3,79.6,37.3,79.7,37.3,79.7z"/><path fill="#9FBAD3" d="M67.8,58.9l-6.2-1.5c-1.9-0.5-3.1-2.1-3.1-4c0-2.3,1.9-4.2,4.2-4.2h3c2.1,0,3.8,1.7,3.8,3.8c0,1.7,1.3,3,3,3s3-1.3,3-3c0-5-3.7-9.1-8.5-9.7v-1.3c0-1.7-1.3-3-3-3s-3,1.3-3,3v1.4c-4.8,0.8-8.5,5-8.5,10c0,4.7,3.2,8.7,7.7,9.9l6.2,1.5c1.9,0.5,3.1,2.1,3.1,4c0,2.3-1.9,4.2-4.2,4.2l-3,0c-2.1,0-3.8-1.7-3.8-3.8c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,5,3.7,9.1,8.5,9.7v1.3c0,1.7,1.3,3,3,3s3-1.3,3-3v-1.4c4.8-0.8,8.5-5,8.5-10C75.5,64,72.3,60,67.8,58.9z"/><path fill="#454B54" d="M64 122H44c-1.7 0-3-1.3-3-3s1.3-3 3-3h20c1.7 0 3 1.3 3 3S65.7 122 64 122zM34 122c-.8 0-1.6-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1s.3-1.6.9-2.1c1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.3.9 2.1s-.3 1.6-.9 2.1C35.6 121.7 34.8 122 34 122z"/></svg>
  );
};

DollarPlaceMarker.defaultProps = {
  width: 30
};

export default DollarPlaceMarker;
