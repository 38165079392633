import React from 'react';

const NO_SCROLL_CLASS = 'noscroll';
let scrollLockCount = 0;

export const useLockScroll = () => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      const container = document.querySelector('body');
      scrollLockCount += 1;
      container.classList.add(NO_SCROLL_CLASS);

      return () => {
        scrollLockCount -= 1;
        if (scrollLockCount === 0) {
          container.classList.remove(NO_SCROLL_CLASS);
        }
      };
    }
  });
};