import React from 'react';

const ReceiveCash3 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M123.9,87.4c-1.8-4-6.7-5.6-10.7-3.9l-3.5,1.5l-0.7-1.4c-1.7-4.1-6.4-6-10.5-4.2l-20.8,9.1 c-0.8-1.3-2-2.3-3.5-3L48.4,73.9c-10-4.5-21.7-3.1-30.4,3.6l-3.9,3l-1,1v0H4c-1.7,0-3,1.3-3,3v32h12v-5.2l0.9-0.5 c3.3-1.9,7.4-2,10.8-0.3l22.2,11.1c5.2,2.5,11.2,2.7,16.6,0.4l56.4-23.8C123.9,96.4,125.8,91.5,123.9,87.4z"/><path fill="#444b54" d="M126.8,86.5c-1.1-2.7-3.3-4.8-6-5.9c-2.7-1.1-5.7-1.1-8.4,0.1l-1.2,0.5c-2.8-4.7-8.6-6.7-13.8-4.5l-11.6,4.9 c-1.5,0.6-2.2,2.4-1.6,3.9c0.5,1.1,1.6,1.8,2.8,1.8c0.4,0,0.8-0.1,1.2-0.2l11.6-4.9c2.1-0.9,4.4-0.2,5.8,1.4l-23.5,10 c0.3-4.5-2.1-8.9-6.5-10.8L49.6,71.2c-11-4.9-23.8-3.4-33.4,3.9l-3.9,3c-0.1,0.1-0.2,0.2-0.3,0.3l-1,1c-0.6,0.6-0.9,1.3-0.9,2.1v32 H4c-1.7,0-3,1.3-3,3s1.3,3,3,3h9c1.7,0,3-1.3,3-3V82.7l3.8-2.9c7.9-6,18.3-7.2,27.4-3.2L73,88.2c1.2,0.5,2.2,1.5,2.6,2.8 s0.4,2.6-0.1,3.8C75,96,74,96.9,72.8,97.4c-1.2,0.5-2.6,0.4-3.8-0.1l-20-8.9c-1.5-0.7-3.3,0-4,1.5c-0.7,1.5,0,3.3,1.5,4l20,8.9 c2.7,1.2,5.7,1.3,8.4,0.2c0.4-0.1,0.8-0.3,1.1-0.5c0.2,0,0.3-0.1,0.4-0.1l38.2-16.2c1.2-0.5,2.6-0.5,3.8,0c1.2,0.5,2.2,1.5,2.7,2.7 c0.5,1.2,0.5,2.6,0,3.8s-1.5,2.2-2.7,2.7l-56.4,23.8c-4.5,1.9-9.7,1.8-14.1-0.4l-23.8-11.6c-1.5-0.7-3.3-0.1-4,1.4s-0.1,3.3,1.4,4 l23.8,11.6c3.2,1.6,6.6,2.3,10.1,2.3c3,0,6.1-0.6,9-1.8l56.4-23.8c2.7-1.1,4.8-3.3,5.9-6C127.9,92.2,127.9,89.2,126.8,86.5z"/><path fill="#71c2ff" d="M71,1C58.3,1,49,11.3,49,24s10.3,23,23,23s23-10.3,23-23S83.7,1,71,1z"/><path fill="#2298e0" d="M95,24C95,11.3,83.7,1,71,1l1,46C84.7,47,95,36.7,95,24z"/><path fill="#71c2ff" d="M72,9c-8.3,0-15,6.7-15,15s6.7,15,15,15s15-6.7,15-15S80.3,9,72,9z"/></svg>
  );
};

ReceiveCash3.defaultProps = {
  width: 30
};

export default ReceiveCash3;
