import React from 'react';

const TransactionList = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M107,5L97,15L87,5L77,15L67,5L57,15L47,5L37,15L27,5v119h70c5.5,0,10-4.5,10-10V5z"/><path fill="#444b54" d="M27 93c-1.7 0-3-1.3-3-3V5c0-1.7 1.3-3 3-3s3 1.3 3 3v85C30 91.7 28.7 93 27 93zM107 72c-1.7 0-3-1.3-3-3V12.2l-4.9 4.9c-1.2 1.2-3.1 1.2-4.2 0L87 9.2l-7.9 7.9c-1.2 1.2-3.1 1.2-4.2 0L67 9.2l-7.9 7.9c-1.2 1.2-3.1 1.2-4.2 0L47 9.2l-2.9 2.9c-1.2 1.2-3.1 1.2-4.2 0-1.2-1.2-1.2-3.1 0-4.2l5-5c1.2-1.2 3.1-1.2 4.2 0l7.9 7.9 7.9-7.9c1.2-1.2 3.1-1.2 4.2 0l7.9 7.9 7.9-7.9c1.2-1.2 3.1-1.2 4.2 0l7.9 7.9 7.9-7.9c.9-.9 2.1-1.1 3.3-.6 1.1.5 1.9 1.6 1.9 2.8v64C110 70.7 108.7 72 107 72z"/><path fill="#fff" d="M87,114v-10H7v10c0,5.5,4.5,10,10,10h80C91.5,124,87,119.5,87,114z"/><path fill="#444b54" d="M97,127H17c-7.2,0-13-5.8-13-13v-10c0-1.7,1.3-3,3-3h80c1.7,0,3,1.3,3,3v10c0,3.9,3.1,7,7,7c1.7,0,3,1.3,3,3 S98.7,127,97,127z M10,107v7c0,3.9,3.1,7,7,7h69c-1.3-2-2-4.4-2-7v-7H10z"/><path fill="#71c2ff" d="M126.2 112.9c0-3.7-1.8-7.1-4.8-9.2l-9.2-6.5c-1.3-1-2.1-2.5-2.1-4.2 0-2.8 2.3-5.1 5.1-5.1 2.1 0 3.9 1.2 4.7 3.1.6 1.5 2.4 2.2 3.9 1.6 1.5-.6 2.2-2.4 1.6-3.9-1.3-3.2-4-5.5-7.2-6.4V82c0-1.7-1.3-3-3-3s-3 1.3-3 3v.4c-4.7 1.3-8.1 5.6-8.1 10.7 0 3.6 1.7 6.9 4.7 9l9.3 6.5c1.4 1 2.3 2.5 2.3 4.2 0 2.8-2.3 5.1-5.1 5.1-2 0-3.9-1.2-4.7-3.1-.7-1.5-2.4-2.2-3.9-1.6-1.5.7-2.2 2.4-1.6 3.9 1.4 3.1 4 5.4 7.2 6.3v.4c0 1.7 1.3 3 3 3s3-1.3 3-3v-.4C122.8 122.3 126.2 118 126.2 112.9zM92 47H58c-1.7 0-3-1.3-3-3s1.3-3 3-3h34c1.7 0 3 1.3 3 3S93.7 47 92 47zM43 41A3 3 0 1 0 43 47 3 3 0 1 0 43 41zM92 62H58c-1.7 0-3-1.3-3-3s1.3-3 3-3h34c1.7 0 3 1.3 3 3S93.7 62 92 62zM43 56A3 3 0 1 0 43 62 3 3 0 1 0 43 56zM92 77H58c-1.7 0-3-1.3-3-3s1.3-3 3-3h34c1.7 0 3 1.3 3 3S93.7 77 92 77zM43 71A3 3 0 1 0 43 77 3 3 0 1 0 43 71z"/></svg>
  );
};

TransactionList.defaultProps = {
  width: 30
};

export default TransactionList;
