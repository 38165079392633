import React from 'react';

const SecurityPass = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M71.9,44.5h31.3c4.3,0,7.8,3.5,7.8,7.8V99c0,4.3-3.5,7.8-7.8,7.8H24.9c-4.3,0-7.8-3.5-7.8-7.8V52.3 c0-4.3,3.5-7.8,7.8-7.8h31.3H71.9"/><path fill="#454b54" d="M103.2,109.8H24.9c-6,0-10.8-4.8-10.8-10.8V52.3c0-5.9,4.9-10.8,10.8-10.8h31.3c1.7,0,3,1.3,3,3 s-1.3,3-3,3H24.9c-2.7,0-4.8,2.1-4.8,4.8V99c0,2.6,2.2,4.8,4.8,4.8h78.3c2.7,0,4.8-2.1,4.8-4.8V52.3c0-2.6-2.2-4.8-4.8-4.8H71.9 c-1.7,0-3-1.3-3-3s1.3-3,3-3h31.3c6,0,10.8,4.8,10.8,10.8V99C114,105,109.2,109.8,103.2,109.8z"/><path fill="#dadbdd" d="M93.5 61H71.9c-1.7 0-3 1.3-3 3 0 1.7 1.3 3 3 3h21.6c1.7 0 3-1.3 3-3S95.2 61 93.5 61M93.5 72.7H71.9c-1.7 0-3 1.3-3 3s1.3 3 3 3h21.6c1.7 0 3-1.3 3-3S95.2 72.7 93.5 72.7M93.5 84.4H71.9c-1.7 0-3 1.3-3 3 0 1.699 1.3 3 3 3h21.6c1.7 0 3-1.301 3-3C96.5 85.7 95.2 84.4 93.5 84.4"/><path fill="#7ca5c6" d="M44.5 64A11.8 11.7 0 1 0 44.5 87.4A11.8 11.7 0 1 0 44.5 64Z"/><path fill="#454b54" d="M44.5,90.4c-8.1,0-14.8-6.601-14.8-14.7c0-8.1,6.6-14.7,14.8-14.7s14.8,6.6,14.8,14.7 C59.2,83.8,52.6,90.4,44.5,90.4z M44.5,67c-4.8,0-8.8,3.9-8.8,8.7s3.9,8.7,8.8,8.7s8.8-3.9,8.8-8.7S49.3,67,44.5,67z"/><path fill="#e5e3e3" d="M70.3,52.3H57.8c-0.9,0-1.6-0.7-1.6-1.6v-28c0-0.9,0.7-1.6,1.6-1.6h12.5c0.9,0,1.601,0.7,1.601,1.6 v28C71.9,51.6,71.2,52.3,70.3,52.3z"/><path fill="#454b54" d="M70.3,55.3H57.8c-2.5,0-4.6-2-4.6-4.6v-28c0-2.5,2-4.6,4.6-4.6h12.5c2.5,0,4.601,2,4.601,4.6v28 C74.9,53.3,72.8,55.3,70.3,55.3z M59.2,49.3h9.7V24.1h-9.7V49.3z"/></svg>
  );
};

SecurityPass.defaultProps = {
  width: 30
};

export default SecurityPass;
