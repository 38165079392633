import React from 'react';

const TransactionList2 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M14,108V25c0-2.761,2.239-5,5-5h90c2.761,0,5,2.239,5,5v83"/><path fill="none" stroke="#9eb9d3" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="6" d="M28 61L35 61M47 61L76 61"/><path fill="#9eb9d3" d="M88 58A3 3 0 1 0 88 64 3 3 0 1 0 88 58zM100 58A3 3 0 1 0 100 64 3 3 0 1 0 100 58z"/><path fill="none" stroke="#9eb9d3" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="6" d="M28 77L35 77M47 77L76 77"/><path fill="#9eb9d3" d="M100 74A3 3 0 1 0 100 80A3 3 0 1 0 100 74Z"/><path fill="none" stroke="#9eb9d3" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="6" d="M28 93L35 93M47 93L76 93"/><path fill="#9eb9d3" d="M88 90A3 3 0 1 0 88 96 3 3 0 1 0 88 90zM100 90A3 3 0 1 0 100 96 3 3 0 1 0 100 90zM114 43H14V25c0-2.761 2.239-5 5-5h90c2.761 0 5 2.239 5 5V43z"/><path fill="#fff" d="M104,30H69c-1.657,0-3,1.343-3,3c0,1.657,1.343,3,3,3h35c1.657,0,3-1.343,3-3S105.657,30,104,30z"/><path fill="none" stroke="#444b54" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="6" d="M14,108V25 c0-2.761,2.239-5,5-5h90c2.761,0,5,2.239,5,5v83"/><path fill="#fff" d="M24 30A3 3 0 1 0 24 36 3 3 0 1 0 24 30zM34 30A3 3 0 1 0 34 36 3 3 0 1 0 34 30zM44 30A3 3 0 1 0 44 36 3 3 0 1 0 44 30z"/></svg>
  );
};

TransactionList2.defaultProps = {
  width: 30
};

export default TransactionList2;
