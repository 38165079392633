import React from 'react';

const CashAndCreditCard = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M114,124c5.5,0,10-4.5,10-10V54c0-5.5-4.5-10-10-10H14C8.5,44,4,48.5,4,54v61.7c0,4.6,3.7,8.3,8.3,8.3H114z"/><path fill="#71c2ff" d="M124,59H4v16h93.4c5.9,5.5,9.6,13.3,9.6,22c0,5.1-1.3,10.1-3.7,14.5c-0.7,1.3-0.4,3.1,0.9,4c0.5,0.4,1.1,0.5,1.7,0.5c1.1,0,2.1-0.6,2.6-1.5c3-5.3,4.5-11.4,4.5-17.5c0-6.9-2-13.4-5.4-18.9c-0.8-1.3,0.1-3.1,1.7-3.1H124V59z"/><path fill="#444b54" d="M42,127H12.3C6.1,127,1,121.9,1,115.7V54c0-7.2,5.8-13,13-13h100c7.2,0,13,5.8,13,13v55c0,1.7-1.3,3-3,3s-3-1.3-3-3V54c0-3.9-3.1-7-7-7H14c-3.9,0-7,3.1-7,7v61.7c0,2.9,2.4,5.3,5.3,5.3H42c1.7,0,3,1.3,3,3S43.7,127,42,127z"/><path fill="#fff" d="M77 70A27 27 0 1 0 77 124A27 27 0 1 0 77 70Z"/><path fill="#444b54" d="M109 121H95c7.3-5.5 12-14.2 12-24 0-16.5-13.5-30-30-30S47 80.5 47 97s13.5 30 30 30c.1 0 .1 0 .2 0H109c1.7 0 3-1.3 3-3S110.7 121 109 121zM53 97c0-13.2 10.8-24 24-24s24 10.8 24 24c0 13.2-10.7 23.9-23.8 24H77c0 0 0 0 0 0C63.7 121 53 110.2 53 97zM124 121A3 3 0 1 0 124 127 3 3 0 1 0 124 121z"/></svg>
  );
};

CashAndCreditCard.defaultProps = {
  width: 30
};

export default CashAndCreditCard;
