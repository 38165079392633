import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Corporation from './SEOHelpers/Corporation';

const SEO = ({ description, lang, meta, title }) => {
  const { contentfulSeo, site } = useStaticQuery(
    graphql`
      query {
        contentfulSeo {
          siteTitle
          siteDescription
        }
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );

  const metaTitle = title ? `${title} | ${contentfulSeo.siteTitle}` : contentfulSeo.siteTitle;
  const metaDescription = description || contentfulSeo.siteDescription;

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      />
      <Corporation />
    </React.Fragment>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
