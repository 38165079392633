import React from 'react';

export const useStateObject = (defaultState) => {
	const [stateObject, setStateObject] = React.useReducer(
		(state, newState) => ({ ...state, ...newState }),
		defaultState
	);
	
	return [stateObject, setStateObject];
};
