import React from 'react';

const BankCards2 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M121.6,13.4c-0.8-4.5-5.1-7.6-9.7-6.8L11.8,24.3c-5.4,1-9.1,6.1-8.1,11.6L4,115.7c0,4.6,3.7,8.3,8.3,8.3H114 c5.5,0,10-4.5,10-10V85.7V52.3V27.1L121.6,13.4z"/><path fill="#71c2ff" d="M118.5,36c3.6,0,6.4-3.3,5.7-6.8v0l-1.5-8.5L58.5,32c-2.4,0.4-2.1,4,0.3,4L118.5,36z"/><path fill="#444b54" d="M3.7 38.9c-1.4 0-2.7-1-3-2.5-1.2-7.1 3.5-13.8 10.5-15.1L111.4 3.7c6.2-1.1 12 3 13.1 9.2l2.4 13.7c.3 1.6-.8 3.2-2.4 3.5-1.6.3-3.2-.8-3.5-2.4l-2.4-13.7c-.5-2.9-3.3-4.8-6.2-4.3L12.3 27.2c-3.8.7-6.3 4.3-5.7 8.1.3 1.6-.8 3.2-2.4 3.5C4 38.9 3.9 38.9 3.7 38.9zM114 127H12.3C6.1 127 1 121.9 1 115.7V54c0-7.2 5.8-13 13-13h101.7c6.2 0 11.3 5.1 11.3 11.3V114C127 121.2 121.2 127 114 127zM14 47c-3.9 0-7 3.1-7 7v61.7c0 2.9 2.4 5.3 5.3 5.3H114c3.9 0 7-3.1 7-7V52.3c0-2.9-2.4-5.3-5.3-5.3H14z"/><path fill="#71c2ff" d="M102.5,54.1c-3.1-0.4-6,0.4-8.3,2c-1,0.7-2.4,0.7-3.4,0c-2.3-1.6-5.2-2.4-8.3-2c-5.4,0.7-9.8,5.1-10.4,10.5 C71.3,71.9,76.9,78,84,78c2.5,0,4.8-0.8,6.7-2.1c1.1-0.7,2.4-0.7,3.5,0c1.9,1.3,4.2,2.1,6.7,2.1c7.1,0,12.7-6.1,11.9-13.4 C112.3,59.2,108,54.8,102.5,54.1z"/></svg>
  );
};

BankCards2.defaultProps = {
  width: 30
};

export default BankCards2;
