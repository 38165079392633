import React from 'react';

const PottedPlant = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M36 58L36 73 46 73 52 124 98 124 104 70 114 70 114 58z"/><path fill="#444b54" d="M114,55H79.2c0.8-3.9,1.2-7.9,1.1-11.9c0-1.7-1.4-2.9-3.1-2.9c-1.7,0-3,1.4-2.9,3.1c0.1,4-0.3,7.9-1.3,11.7H46 c-1.7,0-3,1.3-3,3s1.3,3,3,3h29.4c0,0,0,0,0,0c0,0,0,0,0,0H111v6H49c-1.7,0-3,1.3-3,3s1.3,3,3,3h51.6l-5.3,48H57.4l3.3-27H64 c1.7,0,3-1.3,3-3v-8c0-1.7-1.3-3-3-3H16c-1.7,0-3,1.3-3,3v8c0,1.7,1.3,3,3,3h4.6l1.8,3l-2.3,3l3,24.4c0.2,1.5,1.5,2.6,3,2.6h26h2 h44c1.5,0,2.8-1.1,3-2.7l5.7-51.3h7.3c1.7,0,3-1.3,3-3V58C117,56.3,115.7,55,114,55z"/><path fill="#71c2ff" d="M61.9.8c-.7-.3-1.6-.2-2.3.1-.7.3-1.3.9-1.6 1.7-5.1 14 2.1 29.5 16.1 34.6.3.1.7.2 1 .2.4 0 .9-.1 1.3-.3.7-.3 1.3-.9 1.6-1.7C83.1 21.4 75.8 5.9 61.9.8zM56.6 36.1c-4.4-.4-8.7 1-12 3.8-1.3 1.1-1.4 3-.4 4.2 2.8 3.4 6.8 5.4 11.2 5.8.5 0 1 .1 1.5.1 3.9 0 7.6-1.3 10.6-3.9 1.3-1.1 1.4-3 .4-4.2C65 38.5 61 36.5 56.6 36.1zM119.1 12c-.6-.9-1.5-1.4-2.5-1.6-7.3-1.3-14.8.3-20.9 4.6S85.5 25.7 84.2 33c-.2 1 0 2.1.7 3s1.5 1.4 2.5 1.6c1.6.3 3.3.4 4.9.4 5.7 0 11.2-1.7 16-5.1 6.1-4.3 10.2-10.7 11.5-18C120 13.9 119.7 12.9 119.1 12zM13 44c-1.7 0-3 1.3-3 3 0 14.9 12.1 27 27 27 1.7 0 3-1.3 3-3C40 56.1 27.9 44 13 44z"/><g><path fill="#444b54" d="M35.2,45.2c-1.3,0-2.5-0.8-2.9-2.1c-1.1-3.4-2.4-6.7-4-9.9c-0.7-1.5-0.1-3.3,1.4-4c1.5-0.7,3.3-0.1,4,1.4 c1.7,3.5,3.1,7.1,4.3,10.8c0.5,1.6-0.4,3.3-2,3.8C35.8,45.2,35.5,45.2,35.2,45.2z"/></g><g><path fill="#fff" d="M34,94H19.3l0.7,6H34c1.7,0,3-1.3,3-3S35.7,94,34,94z"/></g></svg>
  );
};

PottedPlant.defaultProps = {
  width: 30
};

export default PottedPlant;
