import React from 'react';

const Atm = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#444b54" d="M110.5,69.3h-90c-3.9,0-7-3.1-7-7l0,0c0-3.9,3.1-7,7-7h90c3.9,0,7,3.1,7,7l0,0 C117.5,66.1,114.4,69.3,110.5,69.3z"/><path fill="#444b54" d="M110.5 72.3h-90c-5.5 0-10-4.5-10-10s4.5-10 10-10h90c5.5 0 10 4.5 10 10S116 72.3 110.5 72.3zM20.5 58.3c-2.2 0-4 1.8-4 4s1.8 4 4 4h90c2.2 0 4-1.8 4-4s-1.8-4-4-4C110.5 58.3 20.5 58.3 20.5 58.3zM30.5 43.7V15.4c0-1.7 1.3-3 3-3h61c1.7 0 3 1.3 3 3v28.3c0 1.7-1.3 3-3 3h-61C31.8 46.7 30.5 45.3 30.5 43.7zM106.3 46.5h8.2c1.7 0 3-1.3 3-3v-8.2c0-1.7-1.3-3-3-3h-8.2c-1.7 0-3 1.3-3 3v8.2C103.3 45.2 104.7 46.5 106.3 46.5zM106.3 26.5h8.2c1.7 0 3-1.3 3-3v-8.2c0-1.7-1.3-3-3-3h-8.2c-1.7 0-3 1.3-3 3v8.2C103.3 25.2 104.7 26.5 106.3 26.5zM13.5 26.6h8.2c1.7 0 3-1.3 3-3v-8.2c0-1.7-1.3-3-3-3h-8.2c-1.7 0-3 1.3-3 3v8.2C10.5 25.2 11.8 26.6 13.5 26.6zM13.5 46.6h8.2c1.7 0 3-1.3 3-3v-8.2c0-1.7-1.3-3-3-3h-8.2c-1.7 0-3 1.3-3 3v8.2C10.5 45.2 11.8 46.6 13.5 46.6z"/><path fill="#6ec6af" d="M99.2,62.2v43.5c0,5.5-4.5,10-10,10h-50c-5.5,0-10-4.5-10-10V62.2H99.2"/><path fill="#c5e8df" d="M89.2,62.2H78.9c0.2,1,0.3,2,0.3,3c0,8.3-6.7,15-15,15c-8.3,0-15-6.7-15-15c0-1,0.1-2,0.3-3H39.2 v33.5c5.5,0,10,4.5,10,10h30c0-5.5,4.5-10,10-10V62.2"/><path fill="#e8f6f2" d="M78.9,62.2H49.5c-0.2,1-0.3,2-0.3,3c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15 C79.2,64.2,79.1,63.2,78.9,62.2"/><path fill="#444b54" d="M89.2,118.7h-50c-7.2,0-13-5.8-13-13V62.2c0-1.7,1.3-3,3-3s3,1.3,3,3v43.5c0,3.9,3.1,7,7,7h50 c3.9,0,7-3.1,7-7V62.2c0-1.7,1.3-3,3-3s3,1.3,3,3v43.5C102.2,112.8,96.4,118.7,89.2,118.7z"/></svg>
  );
};

Atm.defaultProps = {
  width: 30
};

export default Atm;
