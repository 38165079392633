import React from 'react';

const MobilePayment3 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#FFF" d="M44,9h50c5.5,0,10,4.5,10,10v90c0,5.5-4.5,10-10,10H44c-5.5,0-10-4.5-10-10V19C34,13.5,38.5,9,44,9z"/><path fill="#9FBAD3" d="M72.8,61.9l-6.2-1.5c-1.9-0.5-3.1-2.1-3.1-4c0-2.3,1.9-4.2,4.2-4.2h3c2.1,0,3.8,1.7,3.8,3.8c0,1.7,1.3,3,3,3s3-1.3,3-3c0-5-3.7-9.1-8.5-9.7v-1.3c0-1.7-1.3-3-3-3s-3,1.3-3,3v1.4c-4.8,0.8-8.5,5-8.5,10c0,4.7,3.2,8.7,7.7,9.9l6.2,1.5c1.9,0.5,3.1,2.1,3.1,4c0,2.3-1.9,4.2-4.2,4.2l-3,0c-2.1,0-3.8-1.7-3.8-3.8c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,5,3.7,9.1,8.5,9.7v1.3c0,1.7,1.3,3,3,3s3-1.3,3-3v-1.4c4.8-0.8,8.5-5,8.5-10C80.5,67,77.3,63,72.8,61.9z"/><path fill="#E8EDF3" d="M104,109v-8H34v8c0,5.5,4.5,10,10,10h50C99.5,119,104,114.5,104,109z"/><path fill="#454B54" d="M94,122H44c-7.2,0-13-5.8-13-13V19c0-7.2,5.8-13,13-13h50c7.2,0,13,5.8,13,13v90C107,116.2,101.2,122,94,122z M37,60.7V109c0,3.9,3.1,7,7,7h50c3.9,0,7-3.1,7-7V19c0-3.9-3.1-7-7-7H44c-3.9,0-7,3.1-7,7V60.7z"/><path fill="#454B54" d="M89,122H38c-7.2,0-13-5.8-13-13c0-0.1,0-0.1,0-0.2l6-90c0.1-1.7,1.6-2.9,3.2-2.8c1.7,0.1,2.9,1.5,2.8,3.2l-6,89.9c0,3.8,3.2,6.9,7,6.9h51c1.7,0,3,1.3,3,3S90.7,122,89,122z"/><path fill="#454B54" d="M74 122H19c-1.7 0-3-1.3-3-3s1.3-3 3-3h55c1.7 0 3 1.3 3 3S75.7 122 74 122zM10 122c-.8 0-1.6-.3-2.1-.9C7.3 120.6 7 119.8 7 119s.3-1.6.9-2.1c1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.3.9 2.1s-.3 1.6-.9 2.1C11.6 121.7 10.8 122 10 122z"/><path fill="#FFF" d="M69 105A4 4 0 1 0 69 113A4 4 0 1 0 69 105Z"/><path fill="#E8EDF3" d="M74,22H64c-1.7,0-3-1.3-3-3s1.3-3,3-3h10c1.7,0,3,1.3,3,3S75.7,22,74,22z"/></svg>
  );
};

MobilePayment3.defaultProps = {
  width: 30
};

export default MobilePayment3;
