import React from 'react';

const Box = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#444b54" d="M66.3,28.1c-0.8-1-2.1-1.4-3.3-0.9l-48,17c-0.7,0.2-1.2,0.7-1.6,1.3l-12,20c-0.7,1.1-0.6,2.5,0.2,3.4C2.3,69.6,3.1,70,4,70c0.3,0,0.7-0.1,1-0.2l48-17c0.7-0.2,1.2-0.7,1.6-1.3l12-20C67.2,30.5,67.1,29.1,66.3,28.1z"/><path fill="#fff" d="M112 47L64 30 16 47 16 107 64 124 112 107z"/><path fill="#fff" d="M112 47L64 30 16 47 16 107 64 124 112 107zM112 47L64 30"/><path fill="#444b54" d="M64,127c-0.3,0-0.7-0.1-1-0.2l-48-17c-1.2-0.4-2-1.6-2-2.8V47c0-1.3,0.8-2.4,2-2.8l48-17c1.6-0.6,3.3,0.3,3.8,1.8c0.6,1.6-0.3,3.3-1.8,3.8L19,49.1v55.8l46,16.3c1.6,0.6,2.4,2.3,1.8,3.8C66.4,126.2,65.2,127,64,127z"/><path fill="#fff" d="M112 47L64 30M112 47L64 30M64 64L16 47M124 67L76 84 64 64 112 47z"/><path fill="#444b54" d="M126.6,65.5l-12-20c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0l-48-17c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.5,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.1l-48,17c-1.2,0.4-2,1.6-2,2.8v60c0,1.3,0.8,2.4,2,2.8l48,17c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.4,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.5,0c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0l48-17c1.2-0.4,2-1.6,2-2.8V73.4l10-3.5c0.8-0.3,1.5-1,1.8-1.8S127,66.2,126.6,65.5z M103,47L67,59.8V34.2L103,47z M19,51.2l42,14.9v53.6l-42-14.9V51.2z M109,104.9l-42,14.9V74.8l6.4,10.7C74,86.5,75,87,76,87c0.3,0,0.7-0.1,1-0.2l32-11.3V104.9z M77.3,80.4l-8.9-14.8l42.2-15l8.9,14.8L77.3,80.4z"/></svg>
  );
};

Box.defaultProps = {
  width: 30
};

export default Box;
