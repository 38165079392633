import React from 'react';

const CreditCardContactless = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M104,99H24c-5.5,0-10-4.5-10-10V39c0-5.5,4.5-10,10-10h80c5.5,0,10,4.5,10,10v50C114,94.5,109.5,99,104,99z"/><path fill="#f8e390" d="M24 58H42V70H24z"/><path fill="#eceae9" d="M24 80H36V86H24zM42 80H54V86H42zM60 80H72V86H60zM78 80H90V86H78z"/><path fill="#444b54" d="M104,102H24c-7.2,0-13-5.8-13-13V39c0-7.2,5.8-13,13-13h80c7.2,0,13,5.8,13,13v50C117,96.2,111.2,102,104,102 z M24,32c-3.9,0-7,3.1-7,7v50c0,3.9,3.1,7,7,7h80c3.9,0,7-3.1,7-7V39c0-3.9-3.1-7-7-7H24z"/><g><path fill="#75a4cb" d="M91.1 68.4c-.8 0-1.5-.3-2.1-.9-2.7-2.7-7.2-2.7-9.9 0-1.2 1.2-3.1 1.2-4.2 0-1.2-1.2-1.2-3.1 0-4.2 2.5-2.5 5.7-3.8 9.2-3.8s6.7 1.4 9.2 3.8c1.2 1.2 1.2 3.1 0 4.2C92.6 68.1 91.8 68.4 91.1 68.4zM98.1 61.3c-.8 0-1.5-.3-2.1-.9-6.6-6.6-17.4-6.6-24 0-1.2 1.2-3.1 1.2-4.2 0-1.2-1.2-1.2-3.1 0-4.2 9-9 23.6-9 32.5 0 1.2 1.2 1.2 3.1 0 4.2C99.7 61.1 98.9 61.3 98.1 61.3zM84 69.5A3 3 0 1 0 84 75.5 3 3 0 1 0 84 69.5z"/></g></svg>
  );
};

CreditCardContactless.defaultProps = {
  width: 30
};

export default CreditCardContactless;
