import React from 'react';

const DebitCard = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M124,32.3c0-4.6-3.7-8.3-8.3-8.3H14C8.5,24,4,28.5,4,34v61.7c0,4.6,3.7,8.3,8.3,8.3H114c5.5,0,10-4.5,10-10 V32.3z"/><path fill="#444b54" d="M114,107H12.3C6.1,107,1,101.9,1,95.7V34c0-7.2,5.8-13,13-13h101.7c6.2,0,11.3,5.1,11.3,11.3V94 C127,101.2,121.2,107,114,107z M14,27c-3.9,0-7,3.1-7,7v61.7c0,2.9,2.4,5.3,5.3,5.3H114c3.9,0,7-3.1,7-7V32.3 c0-2.9-2.4-5.3-5.3-5.3H14z"/><path fill="#71c2ff" d="M30.3 90h-10c-1.7 0-3-1.3-3-3s1.3-3 3-3h10c1.7 0 3 1.3 3 3S32 90 30.3 90zM54.3 90h-10c-1.7 0-3-1.3-3-3s1.3-3 3-3h10c1.7 0 3 1.3 3 3S56 90 54.3 90zM78.3 90h-10c-1.7 0-3-1.3-3-3s1.3-3 3-3h10c1.7 0 3 1.3 3 3S80 90 78.3 90zM102.3 90h-10c-1.7 0-3-1.3-3-3s1.3-3 3-3h10c1.7 0 3 1.3 3 3S104 90 102.3 90zM34 73H20c-1.7 0-3-1.3-3-3V58c0-1.7 1.3-3 3-3h14c1.7 0 3 1.3 3 3v12C37 71.7 35.7 73 34 73z"/></svg>
  );
};

DebitCard.defaultProps = {
  width: 30
};

export default DebitCard;
