import React from 'react';

const Atm2 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M28 4H100V40H28zM113.7 55H94 34 14.3C9 55 4.4 59 4 64.3 3.6 70.2 8.3 75 14 75h10v39c0 5.5 4.5 10 10 10h60c5.5 0 10-4.5 10-10V75h10c5.7 0 10.4-4.8 10-10.7C123.6 59 119 55 113.7 55z"/><path fill="#71c2ff" d="M73,124h16V67c0-1.7-1.3-3-3-3H76c-1.7,0-3,1.3-3,3V124z"/><path fill="#444b54" d="M97 7v30H31l0-30H97M100 1H28c-1.7 0-3 1.3-3 3l0 36c0 1.7 1.3 3 3 3h72c1.7 0 3-1.3 3-3V4C103 2.3 101.7 1 100 1L100 1zM4 19h12c1.7 0 3-1.3 3-3V4c0-1.7-1.3-3-3-3H4C2.3 1 1 2.3 1 4v12C1 17.7 2.3 19 4 19zM4 43h12c1.7 0 3-1.3 3-3V28c0-1.7-1.3-3-3-3H4c-1.7 0-3 1.3-3 3v12C1 41.7 2.3 43 4 43zM112 19h12c1.7 0 3-1.3 3-3V4c0-1.7-1.3-3-3-3h-12c-1.7 0-3 1.3-3 3v12C109 17.7 110.3 19 112 19zM112 43h12c1.7 0 3-1.3 3-3V28c0-1.7-1.3-3-3-3h-12c-1.7 0-3 1.3-3 3v12C109 41.7 110.3 43 112 43zM114 51.8H14c-7.2 0-13 5.8-13 13V65c0 7.2 5.8 13 13 13 1.7 0 3-1.3 3-3s-1.3-3-3-3c-3.9 0-7-3.1-7-7v-.2c0-3.9 3.1-7 7-7h100c3.9 0 7 3.1 7 7V65c0 3.9-3.1 7-7 7h-7v-5c0-1.7-1.3-3-3-3s-3 1.3-3 3v47c0 3.9-3.1 7-7 7H34c-3.9 0-7-3.1-7-7V67c0-1.7-1.3-3-3-3s-3 1.3-3 3v47c0 7.2 5.8 13 13 13h60c7.2 0 13-5.8 13-13V78h7c7.2 0 13-5.8 13-13v-.2C127 57.6 121.2 51.8 114 51.8z"/></svg>
  );
};

Atm2.defaultProps = {
  width: 30
};

export default Atm2;
