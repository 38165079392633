import React from 'react';

const ChipCard2 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#444b54" d="M108.2,4.4c-2.5-2.5-5.8-3.8-9.3-3.8L57.6,1c-3,0-6,1.1-8.3,3.1L20.6,28.2c-2.9,2.5-4.6,6.1-4.6,9.9V114 c0,7.2,5.8,13,13,13h70c7.2,0,13-5.8,13-13V13.6C112,10.1,110.6,6.8,108.2,4.4z"/><path fill="#fff" d="M82,106H46c-5.5,0-10-4.5-10-10V60c0-5.5,4.5-10,10-10h36c5.5,0,10,4.5,10,10v36C92,101.5,87.5,106,82,106z"/><path fill="#444b54" d="M82,47H46c-7.2,0-13,5.8-13,13v36c0,7.2,5.8,13,13,13h36c7.2,0,13-5.8,13-13V60C95,52.8,89.2,47,82,47z M76,72h13v12H76V72z M89,60v6H76V53h6C85.9,53,89,56.1,89,60z M46,53h24v50H57V69c0-1.7-1.3-3-3-3H39v-6C39,56.1,42.1,53,46,53z M39,72h12v12H39V72z M39,96v-6h12v13h-5C42.1,103,39,99.9,39,96z M82,103h-6V90h13v6C89,99.9,85.9,103,82,103z"/></svg>
  );
};

ChipCard2.defaultProps = {
  width: 30
};

export default ChipCard2;
