import React from 'react';

const CashInHand = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#cbded6" d="M120,33.68L83,99l-16-9l16.47-19.92c1.02-1.39,1.53-3.03,1.53-4.64c0-1.94-0.74-3.85-2.21-5.26 c-2.47-2.59-6.52-2.83-9.22-0.47L63.25,68.9c-0.92,0.78-2.06,1.18-3.21,1.18c-0.9,0-1.81-0.25-2.62-0.75 c-2.29-1.42-3.01-4.41-1.62-6.71L66.82,43l17.09-30L120,33.68z"/><path fill="none" stroke="#444b54" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="6" d="M66.175,44.123L83.91,13L120,33.681L83,99l-16-9"/><path fill="#fff" d="M83.497 41.997A13.5 13.502 0 1 0 83.497 69.001A13.5 13.502 0 1 0 83.497 41.997Z" transform="rotate(-89.748 83.497 55.499)"/><path fill="#f0d6bf" d="M27,78.361l2.025-0.707l15.748-25.46c1.125-1.65,2.475-2.829,4.274-3.536L66.815,43L55.799,62.617 c-1.391,2.303-0.671,5.293,1.617,6.716l0,0c1.83,1.139,4.191,0.963,5.83-0.435l10.321-9.191c2.7-2.357,6.749-2.122,9.224,0.471l0,0 c2.7,2.593,2.925,6.836,0.675,9.901L66.369,92.27c-3.15,4.243-7.649,7.072-12.598,8.251L34.424,105"/><path fill="#9fbad3" d="M6,82l15.7-5.754c1.143-0.468,2.437,0.165,2.777,1.358l9.413,31.358 c0.292,1.025-0.232,2.106-1.215,2.507L24,115C7,98,6,82,6,82z"/><path fill="none" stroke="#444b54" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="6" d="M27 78.361l2.025-.707 15.748-25.46c1.125-1.65 2.475-2.829 4.274-3.536L66.815 43 55.799 62.617c-1.391 2.303-.671 5.293 1.617 6.716l0 0c1.83 1.139 4.191.963 5.83-.435l10.321-9.191c2.7-2.357 6.749-2.122 9.224.471l0 0c2.7 2.593 2.925 6.836.675 9.901L66.369 92.27c-3.15 4.243-7.649 7.072-12.598 8.251L34.424 105M6 82l15.7-5.754c1.143-.468 2.437.165 2.777 1.358l9.413 31.358c.292 1.025-.232 2.106-1.215 2.507L24 115"/></svg>
  );
};

CashInHand.defaultProps = {
  width: 30
};

export default CashInHand;
