import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../Shared/Icons';
import Link from '../Link/Link';

const EmptyIcon = () => null;

const FlyoutMenu = ({ sublinks, bottomLink }) => {
  return (
      <div className="absolute z-30 -ml-4 transform w-screen max-w-md md:max-w-3xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
        <div className="width-full flex justify-center items-end cursor-pointer" style={{ height: '17px' }}>
          <div className="caret border-white shadow-lg" />
        </div>
        <div className="rounded-lg shadow-lg">
          <div className="rounded-lg shadow-xs overflow-hidden">
            <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
              {sublinks.map(link => {
                const Icon = Icons[link.icon] || EmptyIcon;

                return (
                  <Link key={link.label} to={link.url} className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                    <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white sm:h-12 sm:w-12">
                      <Icon width={33} height={33} />
                    </div>
                    <div className="space-y-1">
                      <p className="text-base leading-6 font-medium text-gray-900">
                        {link.label}
                      </p>
                      <p className="text-sm leading-5 text-gray-500">
                        {link.description.description}
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
            {bottomLink && (
              <div className="p-5 bg-gray-50 sm:p-8">
                <Link to={bottomLink.url} className="-m-3 p-3 flow-root space-y-1 rounded-md hover:bg-gray-100 transition ease-in-out duration-150">
                  <div className="flex items-center space-x-3">
                    <div className="text-base leading-6 font-medium text-gray-900">
                      {bottomLink.label}
                    </div>
                  </div>
                  <p className="text-sm leading-5 text-gray-500">
                    {bottomLink.description.description}
                  </p>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
  );
};

FlyoutMenu.propTypes = {
  sublinks: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    description: PropTypes.shape({
      description: PropTypes.string
    })
  })).isRequired,
  bottomLink: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    description: PropTypes.shape({
      description: PropTypes.string
    })
  })
};

export default FlyoutMenu;