import React from 'react';

const BankCards = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M104,124H14c-5.5,0-10-4.5-10-10V54c0-5.5,4.5-10,10-10h90c5.5,0,10,4.5,10,10v60 C114,119.5,109.5,124,104,124z"/><path fill="#444b54" d="M114,1H24c-7.2,0-13,5.8-13,13v18c0,1.7,1.3,3,3,3c0,0,0,0,0,0l17,0c1.7,0,3,1.3,3,3s-1.3,3-3,3v0H14 C6.8,41,1,46.8,1,54v60c0,7.2,5.8,13,13,13h90c7.2,0,13-5.8,13-13V86.6c5.7-1.4,10-6.5,10-12.6V14C127,6.8,121.2,1,114,1z M111,114c0,3.9-3.1,7-7,7H14c-3.9,0-7-3.1-7-7V75h95c1.7,0,3-1.3,3-3V62c0-1.7-1.3-3-3-3H7v-5c0-3.9,3.1-7,7-7h90 c3.9,0,7,3.1,7,7V114z"/><path fill="#fff" d="M108.1,11c-2.4,0-4.5,0.9-6.1,2.4c-0.8,0.7-2,0.7-2.8,0c-1.6-1.5-3.8-2.4-6.1-2.4c-4.8,0-8.8,4-8.9,8.7 c-0.1,5.1,3.9,9.3,9,9.3c2.3,0,4.5-0.9,6.1-2.4c0.8-0.7,2-0.7,2.8,0c1.6,1.5,3.7,2.4,6.1,2.4c5.1,0,9.1-4.2,9-9.3 C116.9,15,112.8,11,108.1,11z"/></svg>
  );
};

BankCards.defaultProps = {
  width: 30
};

export default BankCards;
