import React from 'react';
import PropTypes from 'prop-types';
import { useClassNames } from '../../utils/Class';

const BASE_TEXTAREA_CLASS = `
	form-input
	py-3
	px-4
	block
	w-full
	mb-4
`;

const TextareaField = ({ value, onChange, textareaClass }) => {
	const fullTextareaClass = useClassNames(BASE_TEXTAREA_CLASS, textareaClass);

	return (
		<textarea
			className={fullTextareaClass}
			value={value}
			onChange={onChange}
			style={{
				minHeight: 200
			}}
		/>
	);
};

TextareaField.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	textareaClass: PropTypes.string
};

export default TextareaField;