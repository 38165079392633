import React from 'react';

const InsertMoney = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M109.2,27.8h-90c-3.9,0-7-3.1-7-7l0,0c0-3.9,3.1-7,7-7h90c3.9,0,7,3.1,7,7l0,0 C116.2,24.7,113.1,27.8,109.2,27.8z"/><path fill="#6ec6af" d="M99.2,21.2v83c0,5.5-4.5,10-10,10h-50c-5.5,0-10-4.5-10-10v-83H99.2"/><path fill="#c5e8df" d="M89.2 21.2L39.2 21.2 39.2 104.2 89.2 104.2 89.2 21.2"/><path fill="#444b54" d="M89.2,117.2h-50c-7.2,0-13-5.8-13-13v-80c0-1.7,1.3-3,3-3s3,1.3,3,3v80c0,3.9,3.1,7,7,7h50 c3.9,0,7-3.1,7-7v-80c0-1.7,1.3-3,3-3s3,1.3,3,3v80C102.2,111.4,96.4,117.2,89.2,117.2z"/><path fill="#fff" d="M64.2 49.2A15 15 0 1 0 64.2 79.2A15 15 0 1 0 64.2 49.2Z"/><path fill="#444b54" d="M109.2,30.8c-1.7,0-3-1.3-3-3s1.3-3,3-3c2.2,0,4-1.8,4-4s-1.8-4-4-4h-90c-2.2,0-4,1.8-4,4s1.8,4,4,4 c1.7,0,3,1.3,3,3s-1.3,3-3,3c-5.5,0-10-4.5-10-10s4.5-10,10-10h90c5.5,0,10,4.5,10,10S114.7,30.8,109.2,30.8z"/></svg>
  );
};

InsertMoney.defaultProps = {
  width: 30
};

export default InsertMoney;
