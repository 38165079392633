import React from 'react';
import Link from '../Link/Link';
import Accordion from '../Accordion/Accordion';

import Icons from '../Shared/Icons';

const EmptyIcon = () => null;

const MobileMenuItem = (props) => {
  const { label, sublinks, setMobileMenuOpen } = props;

  return (
    <Accordion
      title={label}
      className="text-xl flex items-center focus:outline-none text-left w-full pb-2 border-b border-gray-100 font-semibold text-gray-900"
      containerClassName="mt-3"
    >
      {sublinks.map(link => {
        const Icon = Icons[link.icon] || EmptyIcon;

        return (
          <Link
            key={link.label}
            to={link.url}
            className="p-2 mb-2 flex items-center rounded-lg hover:bg-gray-50"
            onClick={() => setMobileMenuOpen(false)}
          >
            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white sm:h-12 sm:w-12">
              <Icon width={33} height={33} />
            </div>
            <div className="space-y-1 ml-3">
              <p className="text-base leading-6 font-medium text-gray-900">
                {link.label}
              </p>
            </div>
          </Link>
        );
      })}
    </Accordion>
  );
};

export default MobileMenuItem;
