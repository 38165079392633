import ApplePay from './ApplePay';
import Atm2 from './Atm2';
import Atm from './Atm';
import BankCards2 from './BankCards2';
import BankCards3 from './BankCards3';
import BankCards from './BankCards';
import BankSafe from './BankSafe';
import Bill from './Bill';
import Bonds from './Bonds';
import Box from './Box';
import CardSecurity from './CardSecurity';
import CashAndCreditCard from './CashAndCreditCard';
import CashInHand from './CashInHand';
import CashReceipt from './CashReceipt';
import ChipCard2 from './ChipCard2';
import ChipCard from './ChipCard';
import Contact from './Contact';
import ContactDetails2 from './ContactDetails2';
import ContactDetails from './ContactDetails';
import CreditCard from './CreditCard';
import CreditCardContactless from './CreditCardContactless';
import DebitCard from './DebitCard';
import Deposit from './Deposit';
import DollarPlaceMarker from './DollarPlaceMarker';
import Donate from './Donate';
import Donation from './Donation';
import EconomicGrowth from './EconomicGrowth';
import EmailOpen from './EmailOpen';
import FemaleUser from './FemaleUser';
import FinancialGrowthAnalysis from './FinancialGrowthAnalysis';
import GeneralLedger from './GeneralLedger';
import GetCash from './GetCash';
import Headquarters from './Headquarters';
import HotLine from './HotLine';
import InsertCard from './InsertCard';
import InsertCreditCard from './InsertCreditCard';
import InsertMoney from './InsertMoney';
import Invoice from './Invoice';
import JeepWrangler from './JeepWrangler';
import Location from './Location';
import MastercardCreditCard from './MastercardCreditCard';
import MerchantAccount2 from './MerchantAccount2';
import MerchantAccount3 from './MerchantAccount3';
import MerchantAccount4 from './MerchantAccount4';
import MerchantAccount from './MerchantAccount';
import MobilePayment2 from './MobilePayment2';
import MobilePayment3 from './MobilePayment3';
import MobilePayment from './MobilePayment';
import Money from './Money';
import MoneyBag from './MoneyBag';
import MoneyBox from './MoneyBox';
import MoneyCirculation2 from './MoneyCirculation2';
import MoneyCirculation from './MoneyCirculation';
import MoneyTransfer from './MoneyTransfer';
import News from './News';
import OnlinePayment from './OnlinePayment';
import OnlinePricing from './OnlinePricing';
import OnlineShop from './OnlineShop';
import PayDate from './PayDate';
import PhoneBubble from './PhoneBubble';
import Pickup from './Pickup';
import PickupFrontView from './PickupFrontView';
import PosTerminal from './PosTerminal';
import PottedPlant from './PottedPlant';
import Profit from './Profit';
import ProfitAnalysis from './ProfitAnalysis';
import ReceiptTerminal from './ReceiptTerminal';
import ReceiveCash2 from './ReceiveCash2';
import ReceiveCash3 from './ReceiveCash3';
import ReceiveCash from './ReceiveCash';
import SafeIn from './SafeIn';
import SafeOk from './SafeOk';
import SecurityPass from './SecurityPass';
import Sedan from './Sedan';
import ShopLocation from './ShopLocation';
import StackOfMoney from './StackOfMoney';
import Transaction from './Transaction';
import TransactionList2 from './TransactionList2';
import TransactionList3 from './TransactionList3';
import TransactionList4 from './TransactionList4';
import TransactionList from './TransactionList';
import UsDollar from './UsDollar';
import UserGroups from './UserGroups';
import Visa from './Visa';
import Wallet2 from './Wallet2';
import Wallet from './Wallet';

export default {
  ApplePay,
  Atm2,
  Atm,
  BankCards2,
  BankCards3,
  BankCards,
  BankSafe,
  Bill,
  Bonds,
  Box,
  CardSecurity,
  CashAndCreditCard,
  CashInHand,
  CashReceipt,
  ChipCard2,
  ChipCard,
  Contact,
  ContactDetails2,
  ContactDetails,
  CreditCard,
  CreditCardContactless,
  DebitCard,
  Deposit,
  DollarPlaceMarker,
  Donate,
  Donation,
  EconomicGrowth,
  EmailOpen,
  FemaleUser,
  FinancialGrowthAnalysis,
  GeneralLedger,
  GetCash,
  Headquarters,
  HotLine,
  InsertCard,
  InsertCreditCard,
  InsertMoney,
  Invoice,
  JeepWrangler,
  Location,
  MastercardCreditCard,
  MerchantAccount2,
  MerchantAccount3,
  MerchantAccount4,
  MerchantAccount,
  MobilePayment2,
  MobilePayment3,
  MobilePayment,
  Money,
  MoneyBag,
  MoneyBox,
  MoneyCirculation2,
  MoneyCirculation,
  MoneyTransfer,
  News,
  OnlinePayment,
  OnlinePricing,
  OnlineShop,
  PayDate,
  PhoneBubble,
  Pickup,
  PickupFrontView,
  PosTerminal,
  PottedPlant,
  Profit,
  ProfitAnalysis,
  ReceiptTerminal,
  ReceiveCash2,
  ReceiveCash3,
  ReceiveCash,
  SafeIn,
  SafeOk,
  SecurityPass,
  Sedan,
  ShopLocation,
  StackOfMoney,
  Transaction,
  TransactionList2,
  TransactionList3,
  TransactionList4,
  TransactionList,
  UsDollar,
  UserGroups,
  Visa,
  Wallet2,
  Wallet
};