import React from 'react';

const PayDate = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M104,124H14c-5.5,0-10-4.5-10-10V24c0-5.5,4.5-10,10-10h100c5.5,0,10,4.5,10,10v80C124,115,115,124,104,124z"/><path fill="#71c2ff" d="M102 77A25 25 0 1 0 102 127A25 25 0 1 0 102 77Z"/><path fill="#fff" d="M110.8,109.1c0-2.9-1.4-5.6-3.8-7.3l-6.6-4.6c-0.7-0.5-1.2-1.4-1.2-2.3c0-1.5,1.2-2.8,2.8-2.8c1.1,0,2.1,0.7,2.6,1.7c0.6,1.5,2.4,2.2,3.9,1.6c1.5-0.6,2.2-2.4,1.6-3.9c-1-2.3-2.9-4-5.1-4.8c-0.2-1.5-1.4-2.7-3-2.7c-1.5,0-2.8,1.2-3,2.7c-3.4,1.2-5.8,4.5-5.8,8.3c0,2.8,1.4,5.5,3.7,7.2l6.6,4.7c0.8,0.5,1.2,1.4,1.2,2.3c0,1.5-1.2,2.8-2.8,2.8c-1.1,0-2.1-0.7-2.6-1.7c-0.7-1.5-2.4-2.2-3.9-1.6c-1.5,0.7-2.2,2.4-1.6,3.9c1,2.3,2.9,3.9,5.1,4.7c0.2,1.5,1.4,2.7,3,2.7c1.5,0,2.8-1.2,3-2.7C108.4,116.1,110.8,112.9,110.8,109.1z"/><path fill="#444b54" d="M69 127H14c-7.2 0-13-5.8-13-13V64c0-1.7 1.3-3 3-3s3 1.3 3 3v50c0 3.9 3.1 7 7 7h55c1.7 0 3 1.3 3 3S70.7 127 69 127zM107.8 47H4c-1.7 0-3-1.3-3-3V24c0-7.2 5.8-13 13-13h15c1.7 0 3 1.3 3 3s-1.3 3-3 3H14c-3.9 0-7 3.1-7 7v17h100.8c1.7 0 3 1.3 3 3S109.4 47 107.8 47z"/><path fill="#444b54" d="M74 11H52V9c0-4.4-3.6-8-8-8s-8 3.6-8 8v10c0 4.4 3.6 8 8 8 1.7 0 3-1.3 3-3s-1.3-3-3-3c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2s2 .9 2 2v5c0 1.7 1.3 3 3 3h25c1.7 0 3-1.3 3-3S75.7 11 74 11zM114 11H97V9c0-4.4-3.6-8-8-8s-8 3.6-8 8v10c0 4.4 3.6 8 8 8 1.7 0 3-1.3 3-3s-1.3-3-3-3c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2s2 .9 2 2v5c0 1.7 1.3 3 3 3h20c3.9 0 7 3.1 7 7v45c0 1.7 1.3 3 3 3s3-1.3 3-3V24C127 16.8 121.2 11 114 11z"/></svg>
  );
};

PayDate.defaultProps = {
  width: 30
};

export default PayDate;
