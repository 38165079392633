import React from 'react';

const MobilePayment = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M114,44H84V14c0-5.5-4.5-10-10-10H14C8.5,4,4,8.5,4,14v40c0,0,0,0,0,0v61.7c0,4.6,3.7,8.3,8.3,8.3H114 c5.5,0,10-4.5,10-10V54C124,48.5,119.5,44,114,44z"/><path fill="#71c2ff" d="M94 62v10c0 1.7 1.3 3 3 3h27V59H97C95.3 59 94 60.3 94 62zM44 77c-4.4 0-8.4-2.6-10.2-6.7-.7-1.5 0-3.3 1.6-3.9 1.5-.7 3.3 0 3.9 1.6C40.1 69.8 42 71 44 71c2.8 0 5.1-2.3 5.1-5.1 0-1.7-.8-3.3-2.3-4.2l-9.3-6.5c-2.9-2.1-4.7-5.5-4.7-9C32.9 40 37.9 35 44 35c4.5 0 8.5 2.7 10.2 6.8.6 1.5-.1 3.3-1.6 3.9-1.5.6-3.3-.1-3.9-1.6C47.9 42.2 46.1 41 44 41c-2.8 0-5.1 2.3-5.1 5.1 0 1.6.8 3.2 2.1 4.2l9.2 6.5c3 2 4.8 5.5 4.8 9.2C55.1 72 50.1 77 44 77z"/><path fill="#71c2ff" d="M44 41c-1.7 0-3-1.3-3-3v-3c0-1.7 1.3-3 3-3s3 1.3 3 3v3C47 39.7 45.7 41 44 41zM44 80c-1.7 0-3-1.3-3-3v-3c0-1.7 1.3-3 3-3s3 1.3 3 3v3C47 78.7 45.7 80 44 80z"/><path fill="#444b54" d="M114,41H96c-1.7,0-3,1.3-3,3s1.3,3,3,3h18c3.9,0,7,3.1,7,7v60c0,3.9-3.1,7-7,7H84.9c1.3-2,2.1-4.4,2.1-7V14 c0-7.2-5.8-13-13-13H14C6.8,1,1,6.8,1,14v100c0,7.2,5.8,13,13,13h100c7.2,0,13-5.8,13-13V54C127,46.8,121.2,41,114,41z M7,114V14 c0-3.9,3.1-7,7-7h60c3.9,0,7,3.1,7,7v100c0,3.9-3.1,7-7,7H14C10.1,121,7,117.9,7,114z"/><g><path fill="#71c2ff" d="M44 103A6 6 0 1 0 44 115A6 6 0 1 0 44 103Z"/></g></svg>
  );
};

MobilePayment.defaultProps = {
  width: 30
};

export default MobilePayment;
