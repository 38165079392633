import React from 'react';

export const useClassNames = (...classes) => {
  const classList = [...classes];

  const computedClass = React.useMemo(() => {
    return classList.reduce((computed, nextClass) => {
      return `${computed} ${nextClass}`;
    }, '');

  }, [classList]);

  return computedClass;
};