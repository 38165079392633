import React from 'react';

const TransactionList4 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#b58fa5" d="M104,5L94,15L84,5L74,15L64,5L54,15L44,5L34,15L24,5v119h80v0c0.1,0,0.2,0,0.2,0C104,124,104,5,104,5z"/><path fill="#444b54" d="M104,127c-1.7,0-3-1.3-3-3V12.2l-4.9,4.9c-1.2,1.2-3.1,1.2-4.2,0L84,9.2l-7.9,7.9c-1.2,1.2-3.1,1.2-4.2,0 L64,9.2l-7.9,7.9c-1.2,1.2-3.1,1.2-4.2,0L44,9.2l-2.9,2.9c-1.2,1.2-3.1,1.2-4.2,0c-1.2-1.2-1.2-3.1,0-4.2l5-5 c1.2-1.2,3.1-1.2,4.2,0l7.9,7.9l7.9-7.9c1.2-1.2,3.1-1.2,4.2,0l7.9,7.9l7.9-7.9c1.2-1.2,3.1-1.2,4.2,0l7.9,7.9l7.9-7.9 c0.9-0.9,2.1-1.1,3.3-0.6c1.1,0.5,1.9,1.6,1.9,2.8v119C107,125.7,105.7,127,104,127z"/><path fill="#fff" d="M75.1,61.9c0-3.7-1.8-7.1-4.8-9.2L61,46.3c-1.3-1-2.1-2.5-2.1-4.2c0-2.8,2.3-5.1,5.1-5.1 c2.1,0,3.9,1.2,4.7,3.1c0.6,1.5,2.4,2.2,3.9,1.6c1.5-0.6,2.2-2.4,1.6-3.9c-1.3-3.2-4-5.5-7.2-6.4V31c0-1.7-1.3-3-3-3s-3,1.3-3,3 v0.4c-4.7,1.3-8.1,5.6-8.1,10.7c0,3.6,1.7,6.9,4.7,9l9.3,6.5c1.4,1,2.2,2.5,2.2,4.2c0,2.8-2.3,5.1-5.1,5.1c-2,0-3.9-1.2-4.7-3.1 c-0.7-1.5-2.4-2.2-3.9-1.6c-1.5,0.7-2.2,2.4-1.6,3.9c1.4,3.1,4,5.4,7.2,6.3V73c0,1.7,1.3,3,3,3s3-1.3,3-3v-0.4 C71.7,71.3,75.1,67,75.1,61.9z"/><path fill="#444b54" d="M83,121H27V5c0-1.7-1.3-3-3-3s-3,1.3-3,3v116H4c-1.7,0-3,1.3-3,3s1.3,3,3,3h79c1.7,0,3-1.3,3-3 S84.7,121,83,121z"/><path fill="#fff" d="M89 92H55c-1.7 0-3-1.3-3-3s1.3-3 3-3h34c1.7 0 3 1.3 3 3S90.7 92 89 92zM40 86A3 3 0 1 0 40 92 3 3 0 1 0 40 86zM89 107H55c-1.7 0-3-1.3-3-3s1.3-3 3-3h34c1.7 0 3 1.3 3 3S90.7 107 89 107zM40 101A3 3 0 1 0 40 107 3 3 0 1 0 40 101z"/></svg>
  );
};

TransactionList4.defaultProps = {
  width: 30
};

export default TransactionList4;
