import React from 'react';
import InputField from './InputField';
import TextareaField from './TextareaField';

// Determines the component type for the field
const getComponent = (type) => {
	switch (type) {
		case 'textarea':
			return TextareaField;
		default:
			return InputField;
	}
};

const Field = ({ label, errorMessage, ...rest }) => {
	const Component = React.useMemo(() => getComponent(rest.type), [rest.type]);

	return (
		<React.Fragment>
			{label && (
				<label
					className="block text-base font-medium leading-5 text-gray-700 mb-2"
					htmlFor={rest.name}
				>
					{label}
				</label>
			)}
			<Component
				hasError={!!errorMessage}
				{...rest}
			/>
			{errorMessage && (
				<span>{errorMessage}</span>
			)}
		</React.Fragment>
	);
};

export default Field;
