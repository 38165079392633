import React from 'react';

const Bill = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M109 113.375L99 103 89 113 79 103 69 113 59 103 49 113 39 103 29 113 19 103 19 15 109 15z"/><path fill="#9eb9d3" d="M19 15H108V32H19z"/><path fill="none" stroke="#444b54" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="6" d="M49 113L39 103M69 113L59 103M89 113L79 103M31 15L109 15 109 113 99 103"/><path fill="none" stroke="#9eb9d3" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M86 47L86 51M86 81L86 85M94 58c0 0 0-7-8-7-7 0-9 4-9 7 0 5 1.094 6.419 9 8 5 1 8 3 8 8 0 1 0 7-8 7-7 0-9-4-9-7M35 47L61 47M35 59L61 59M35 73L61 73M35 85L42 85M54 85L61 85"/><path fill="none" stroke="#444b54" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="6" d="M19 103L19 15M29 113L19 103"/></svg>
  );
};

Bill.defaultProps = {
  width: 30
};

export default Bill;
