import React from 'react';
import PropTypes from 'prop-types';
import { useClassNames } from '../../utils/Class';

const BASE_INPUT_CLASS = `
	form-input
	py-3
	px-4
	block
	w-full
	mb-4
`;

const InputField = ({ inputClass, value, onChange, name }) => {
	const fullInputClass = useClassNames(BASE_INPUT_CLASS, inputClass);

	return (
		<input
			id={name}
			name={name}
			value={value}
			onChange={onChange}
			className={fullInputClass}
		/>
	);
};

InputField.propTypes = {
	inputClass: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired
};

InputField.defaultProps = {
	onChange: () => {},
	inputClass: ''
};

export default InputField;
