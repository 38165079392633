import React from 'react';

const MerchantAccount3 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M14 53H26V112H14zM58 52H70V61H58zM102 52H114V61H102zM84 12.5h-1.4c-4.9-4.4-11.4-7.1-18.5-7.1s-13.6 2.7-18.5 7.1H45 4V43l120-.5v-30H84z"/><path fill="#444b54" d="M26 127H4c-1.7 0-3-1.3-3-3s1.3-3 3-3h22c1.7 0 3 1.3 3 3S27.7 127 26 127zM26 115H14c-1.7 0-3-1.3-3-3V52.5c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3V112C29 113.7 27.7 115 26 115zM17 109h6V55.5h-6V109zM70 62c-1.7 0-3-1.3-3-3v-3.5h-6V59c0 1.7-1.3 3-3 3s-3-1.3-3-3v-6.5c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3V59C73 60.7 71.7 62 70 62zM114 63c-1.7 0-3-1.3-3-3v-4.5h-6V60c0 1.7-1.3 3-3 3s-3-1.3-3-3v-7.5c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3V60C117 61.7 115.7 63 114 63zM124 9.5H84c-.2 0-.3 0-.5.1C78.2 5.1 71.4 2.5 64 2.5c-7.3 0-14.1 2.6-19.4 7H4c-1.7 0-3 1.3-3 3V43c0 .8.3 1.6.9 2.1C2.4 45.7 3.2 46 4 46c0 0 0 0 0 0l120-.5c1.7 0 3-1.3 3-3v-30C127 10.8 125.7 9.5 124 9.5zM121 29.5H94.3c-.6-5.2-2.5-10-5.3-14h32V29.5zM88.2 29.5H39.8c1.7-11.9 12-21 24.2-21C76.3 8.5 86.5 17.6 88.2 29.5zM39 15.5c-2.9 4-4.8 8.8-5.3 14H7v-14H39zM7 40v-4.5h114v4L7 40z"/><path fill="#fff" d="M117.3,124c3.7,0,6.7-3,6.7-6.7v-40c0-3.7-3-6.7-6.7-6.7H50.7c-3.7,0-6.7,3-6.7,6.7v41.1 c0,3.1,2.5,5.6,5.6,5.6H117.3z"/><path fill="#71c2ff" d="M44 81H124V91H44z"/><path fill="#444b54" d="M117.3,127H49.6c-4.7,0-8.6-3.8-8.6-8.6V77.3c0-5.3,4.3-9.7,9.7-9.7h66.7c5.3,0,9.7,4.3,9.7,9.7v40 C127,122.7,122.7,127,117.3,127z M50.7,73.7c-2,0-3.7,1.6-3.7,3.7v41.1c0,1.4,1.1,2.6,2.6,2.6h67.8c2,0,3.7-1.6,3.7-3.7v-40 c0-2-1.6-3.7-3.7-3.7H50.7z"/></svg>
  );
};

MerchantAccount3.defaultProps = {
  width: 30
};

export default MerchantAccount3;
