import React from 'react';

const UsDollar = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M64 14A50 50 0 1 0 64 114A50 50 0 1 0 64 14Z"/><path fill="#3f4a54" d="M64,117c-29.2,0-53-23.8-53-53s23.8-53,53-53s53,23.8,53,53S93.2,117,64,117z M64,17c-25.9,0-47,21.1-47,47s21.1,47,47,47s47-21.1,47-47S89.9,17,64,17z"/><path fill="#3f4a54" d="M67.8,61.9l-6.2-1.5c-1.9-0.5-3.1-2.1-3.1-4c0-2.3,1.9-4.2,4.2-4.2h3c2.1,0,3.8,1.7,3.8,3.8c0,1.7,1.3,3,3,3s3-1.3,3-3c0-5-3.7-9.1-8.5-9.7v-1.3c0-1.7-1.3-3-3-3s-3,1.3-3,3v1.4c-4.8,0.8-8.5,5-8.5,10c0,4.7,3.2,8.7,7.7,9.9l6.2,1.5c1.9,0.5,3.1,2.1,3.1,4c0,2.3-1.9,4.2-4.2,4.2l-3,0c-2.1,0-3.8-1.7-3.8-3.8c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,5,3.7,9.1,8.5,9.7v1.3c0,1.7,1.3,3,3,3s3-1.3,3-3v-1.4c4.8-0.8,8.5-5,8.5-10C75.5,67,72.3,63,67.8,61.9z"/></svg>
  );
};

UsDollar.defaultProps = {
  width: 30
};

export default UsDollar;
