import React from 'react';

const ContactDetails = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#dfe8f4" d="M115,14v100c0,5.5-4.5,10-10,10H29c-5.5,0-10-4.5-10-10V14c0-5.5,4.5-10,10-10h76C110.5,4,115,8.5,115,14z"/><path fill="#fff" d="M69 27A10 10 0 1 0 69 47A10 10 0 1 0 69 27Z"/><path fill="#444b54" d="M69,50c-7.2,0-13-5.8-13-13s5.8-13,13-13s13,5.8,13,13S76.2,50,69,50z M69,30c-3.9,0-7,3.1-7,7s3.1,7,7,7 s7-3.1,7-7S72.9,30,69,30z"/><path fill="#fff" d="M91 92H48c-1.7 0-3-1.3-3-3s1.3-3 3-3h43c1.7 0 3 1.3 3 3S92.7 92 91 92zM76 77H48c-1.7 0-3-1.3-3-3s1.3-3 3-3h28c1.7 0 3 1.3 3 3S77.7 77 76 77z"/><path fill="#444b54" d="M105,1H29c-7.2,0-13,5.8-13,13v7h-2c-4.4,0-8,3.6-8,8s3.6,8,8,8h10c4.4,0,8-3.6,8-8c0-1.7-1.3-3-3-3 s-3,1.3-3,3c0,1.1-0.9,2-2,2H14c-1.1,0-2-0.9-2-2s0.9-2,2-2h5c1.7,0,3-1.3,3-3V14c0-3.9,3.1-7,7-7h76c3.9,0,7,3.1,7,7v100 c0,3.9-3.1,7-7,7H29c-3.9,0-7-3.1-7-7v-7h2c4.4,0,8-3.6,8-8c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,1.1-0.9,2-2,2H14c-1.1,0-2-0.9-2-2 s0.9-2,2-2h5c1.7,0,3-1.3,3-3V82c0-1.7-1.3-3-3-3s-3,1.3-3,3v9h-2c-4.4,0-8,3.6-8,8s3.6,8,8,8h2v7c0,7.2,5.8,13,13,13h76 c7.2,0,13-5.8,13-13V14C118,6.8,112.2,1,105,1z"/><path fill="#444b54" d="M14,62h5c1.7,0,3-1.3,3-3V47c0-1.7-1.3-3-3-3s-3,1.3-3,3v9h-2c-4.4,0-8,3.6-8,8s3.6,8,8,8h10c4.4,0,8-3.6,8-8 c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,1.1-0.9,2-2,2H14c-1.1,0-2-0.9-2-2S12.9,62,14,62z"/><path fill="#fff" d="M91 71A3 3 0 1 0 91 77A3 3 0 1 0 91 71Z"/></svg>
  );
};

ContactDetails.defaultProps = {
  width: 30
};

export default ContactDetails;
