import React from 'react';

const CreditCard = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#addff8" d="M109,101H19c-5.5,0-10-4.5-10-10V37c0-5.5,4.5-10,10-10h90c5.5,0,10,4.5,10,10v54 C119,96.5,114.5,101,109,101z"/><path fill="#7da4c5" d="M9 42H119V58H9z"/><path fill="#454b54" d="M109,104H19c-7.2,0-13-5.8-13-13V37c0-7.2,5.8-13,13-13h90c7.2,0,13,5.8,13,13v54 C122,98.2,116.2,104,109,104z M19,30c-3.9,0-7,3.1-7,7v54c0,3.9,3.1,7,7,7h90c3.9,0,7-3.1,7-7V37c0-3.9-3.1-7-7-7H19z"/><path fill="#fff" d="M23 69H105V80H23z"/></svg>
  );
};

CreditCard.defaultProps = {
  width: 30
};

export default CreditCard;
