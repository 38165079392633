import React from 'react';

const Money = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#FFF" d="M9 49H119V109H9z"/><path fill="#CBDED6" d="M119,109V49l-40.3,4C44.8,56.4,19,74.9,19,109v0H119z"/><path fill="#FFF" d="M67.8,76.6L61.6,75c-1.9-0.5-3.1-2.1-3.1-4c0-2.3,1.9-4.2,4.2-4.2h3c2.1,0,3.8,1.7,3.8,3.8c0,1.7,1.3,3,3,3s3-1.3,3-3c0-5-3.7-9.1-8.5-9.7v-1.3c0-1.7-1.3-3-3-3s-3,1.3-3,3V61c-4.8,0.8-8.5,5-8.5,10c0,4.7,3.2,8.7,7.7,9.9l6.2,1.5c1.9,0.5,3.1,2.1,3.1,4c0,2.3-1.9,4.2-4.2,4.2l-3,0c-2.1,0-3.8-1.7-3.8-3.8c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,5,3.7,9.1,8.5,9.7v1.3c0,1.7,1.3,3,3,3s3-1.3,3-3v-1.4c4.8-0.8,8.5-5,8.5-10C75.5,81.7,72.3,77.7,67.8,76.6z"/><path fill="#454B54" d="M119 112H9c-1.7 0-3-1.3-3-3V49c0-1.7 1.3-3 3-3h110c1.7 0 3 1.3 3 3v60C122 110.7 120.7 112 119 112zM12 106h104V52H12V106zM119 43c-36.9 0-63.8-3.4-97-9.4V40c0 1.7-1.3 3-3 3s-3-1.3-3-3V30c0-.9.4-1.7 1.1-2.3.7-.6 1.6-.8 2.5-.6l.9.2C54.5 33.4 81.6 37 119 37c1.7 0 3 1.3 3 3S120.7 43 119 43z"/></svg>
  );
};

Money.defaultProps = {
  width: 30
};

export default Money;
