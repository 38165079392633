import React from "react"

const Table = ({ rows }) => {
  if (!rows.length) {
    return null
  }

  const headerRow = rows[0]
  const contentRows = rows.slice(1, rows.length).map((row, index) => ({
    id: index,
    columns: row,
  }))

  return (
    <div className="flex flex-col">
      <div className="-mt-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full overflow-hidden border-gray-200">
          <table
            className="border w-full divide-y divide-gray-200"
            style={{ minWidth: "600px" }}
          >
            <thead>
              <tr>
                {headerRow.map(column => (
                  <th
                    className="border px-6 py-3 bg-gray-50 text-left text-lg leading-4 font-medium text-gray-500 tracking-wider"
                    key={column}
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {contentRows.map(row => (
                <tr key={row.id}>
                  {row.columns.map(column => (
                    <td
                      key={column}
                      className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900"
                    >
                      {column}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Table
