import React from 'react';

const MastercardCreditCard = ({ width, height }) => {
  return (
    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={width} height={height}><path d="M 5 6 C 3.3550302 6 2 7.3550302 2 9 L 2 23 C 2 24.64497 3.3550302 26 5 26 L 27 26 C 28.64497 26 30 24.64497 30 23 L 30 9 C 30 7.3550302 28.64497 6 27 6 L 5 6 z M 5 8 L 27 8 C 27.56503 8 28 8.4349698 28 9 L 28 11 L 5 11 L 5 13 L 28 13 L 28 23 C 28 23.56503 27.56503 24 27 24 L 5 24 C 4.4349698 24 4 23.56503 4 23 L 4 9 C 4 8.4349698 4.4349698 8 5 8 z M 18.503906 15 C 16.584963 15 15.007813 16.577151 15.007812 18.496094 C 15.007813 20.415037 16.584963 21.992187 18.503906 21.992188 C 19.247229 21.992187 19.934957 21.751104 20.503906 21.349609 C 21.072856 21.751104 21.760584 21.992187 22.503906 21.992188 C 24.422849 21.992187 26 20.415037 26 18.496094 C 26 16.577151 24.422849 15 22.503906 15 C 21.760584 15 21.072856 15.241084 20.503906 15.642578 C 19.934957 15.241084 19.247229 15 18.503906 15 z M 18.503906 17 C 18.784206 17 19.041535 17.077695 19.263672 17.207031 C 19.102586 17.607363 19.007813 18.040773 19.007812 18.496094 C 19.007813 18.951415 19.102586 19.384824 19.263672 19.785156 C 19.041535 19.914493 18.784206 19.992187 18.503906 19.992188 C 17.665844 19.992186 17.007814 19.334156 17.007812 18.496094 C 17.007813 17.658031 17.665844 17 18.503906 17 z M 22.503906 17 C 23.341969 17 24 17.658031 24 18.496094 C 24 19.334156 23.341969 19.992186 22.503906 19.992188 C 22.223607 19.992187 21.966278 19.914493 21.744141 19.785156 C 21.905226 19.384824 22 18.951415 22 18.496094 C 22 18.040773 21.905226 17.607363 21.744141 17.207031 C 21.966278 17.077695 22.223607 17 22.503906 17 z"/></svg>
  );
};

MastercardCreditCard.defaultProps = {
  width: 30
};

export default MastercardCreditCard;
