import React from 'react';

const ContactDetails2 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#e8edf3" stroke="#444b54" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="6" d="M108,23H20 c-5.523,0-10,4.477-10,10v63c0,5.523,4.477,10,10,10h14v-3.306c-1.81-1.619-2.962-3.954-2.999-6.565 c-0.069-4.838,3.89-8.984,8.727-9.126C44.823,86.854,49,90.939,49,96c0,2.663-1.161,5.049-3,6.694V106h36v-3.306 c-1.81-1.619-2.962-3.954-2.999-6.565c-0.069-4.838,3.89-8.984,8.727-9.126C92.823,86.854,97,90.939,97,96 c0,2.663-1.161,5.049-3,6.694V106h14c5.523,0,10-4.477,10-10V33C118,27.477,113.523,23,108,23z"/><path fill="#f6f6fa" d="M115,33.911V33c0-3.86-3.141-7-7-7H20c-3.859,0-7,3.14-7,7v63c0,0.612,0.087,1.202,0.235,1.768 C19.019,65.484,49.061,33.911,81.5,33.911H115z"/><path fill="none" stroke="#b5c7e1" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M74 47L102 47M74 59L102 59M74 71L90 71"/><path fill="#efd5be" d="M55,47.918C55,57.962,50.075,63,44,63s-11-5.038-11-15.082C33,41.888,37.925,37,44,37 S55,41.888,55,47.918z"/><path fill="#b5c7e1" d="M44,63c-14,0-18,12-18,12v3h36v-3C62,75,57,63,44,63z"/><path fill="none" stroke="#444b54" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="6" d="M26 75c0 0 4-12 18-12 13 0 18 12 18 12M55 47.918C55 57.962 50.075 63 44 63s-11-5.038-11-15.082C33 41.888 37.925 37 44 37S55 41.888 55 47.918z"/><path fill="#b5c7e1" d="M102 68A3 3 0 1 0 102 74A3 3 0 1 0 102 68Z"/></svg>
  );
};

ContactDetails2.defaultProps = {
  width: 30
};

export default ContactDetails2;
