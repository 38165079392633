import React from 'react';

const InsertCreditCard = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M109.2,27.8h-90c-3.9,0-7-3.1-7-7v0c0-3.9,3.1-7,7-7h90c3.9,0,7,3.1,7,7v0C116.2,24.7,113.1,27.8,109.2,27.8z"/><path fill="#fff" d="M29,104V24c0-5.5,4.5-10,10-10h50c5.5,0,10,4.5,10,10v80c0,5.5-4.5,10-10,10H39C33.5,114,29,109.5,29,104z"/><path fill="#f8e390" d="M54 26H74V42H54z" transform="rotate(90 64 34)"/><path fill="#c7d6e2" d="M41 24H47V36H41zM41 42H47V54H41zM41 60H47V72H41zM41 78H47V90H41z"/><path fill="#444b54" d="M89,117H39c-7.2,0-13-5.8-13-13V24c0-1.7,1.3-3,3-3s3,1.3,3,3v80c0,3.9,3.1,7,7,7h50c3.9,0,7-3.1,7-7V24c0-1.7,1.3-3,3-3s3,1.3,3,3v80C102,111.2,96.2,117,89,117z"/><g><path fill="#444b54" d="M109.2,30.8c-1.7,0-3-1.3-3-3s1.3-3,3-3c2.2,0,4-1.8,4-4s-1.8-4-4-4h-90c-2.2,0-4,1.8-4,4s1.8,4,4,4c1.7,0,3,1.3,3,3s-1.3,3-3,3c-5.5,0-10-4.5-10-10s4.5-10,10-10h90c5.5,0,10,4.5,10,10S114.7,30.8,109.2,30.8z"/></g></svg>
  );
};

InsertCreditCard.defaultProps = {
  width: 30
};

export default InsertCreditCard;
