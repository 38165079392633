import React from 'react';

const ShopLocation = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><rect width="100" height="75.4" x="14" y="33.6" fill="#fff"/><path fill="#fff" d="M14,54L14,54C8.5,54,4,49.5,4,44V14C4,8.5,8.5,4,14,4h100c5.5,0,10,4.5,10,10v30c0,5.5-4.5,10-10,10h0 C108.5,54,19.5,54,14,54z"/><path fill="#71c2ff" d="M99,51H29c-1.7,0-3,1.3-3,3v35c0,1.7,1.3,3,3,3h70c1.7,0,3-1.3,3-3V54C102,52.3,100.7,51,99,51z"/><path fill="#71c2ff" d="M114,1H14C6.8,1,1,6.8,1,14v30c0,7.2,5.8,13,13,13c4,0,7.6-1.8,10-4.7c2.4,2.9,6,4.7,10,4.7s7.6-1.8,10-4.7 c2.4,2.9,6,4.7,10,4.7s7.6-1.8,10-4.7c2.4,2.9,6,4.7,10,4.7s7.6-1.8,10-4.7c2.4,2.9,6,4.7,10,4.7s7.6-1.8,10-4.7 c2.4,2.9,6,4.7,10,4.7c7.2,0,13-5.8,13-13V14C127,6.8,121.2,1,114,1z M121,44c0,3.9-3.1,7-7,7s-7-3.1-7-7V24v-5c0-1.7-1.3-3-3-3 s-3,1.3-3,3v5v20c0,3.9-3.1,7-7,7s-7-3.1-7-7V19c0-1.7-1.3-3-3-3s-3,1.3-3,3v25c0,3.9-3.1,7-7,7s-7-3.1-7-7V19c0-1.7-1.3-3-3-3 s-3,1.3-3,3v25c0,3.9-3.1,7-7,7s-7-3.1-7-7V19c0-1.7-1.3-3-3-3s-3,1.3-3,3v25c0,3.9-3.1,7-7,7s-7-3.1-7-7V34c0-1.7-1.3-3-3-3 s-3,1.3-3,3v10c0,3.9-3.1,7-7,7s-7-3.1-7-7V14c0-3.9,3.1-7,7-7h100c3.9,0,7,3.1,7,7V44z"/><path fill="#71c2ff" d="M124,106H17V64c0-1.7-1.3-3-3-3s-3,1.3-3,3v42H4c-1.7,0-3,1.3-3,3s1.3,3,3,3h120c1.7,0,3-1.3,3-3 S125.7,106,124,106z"/><path fill="#71c2ff" d="M74,127H24c-1.7,0-3-1.3-3-3s1.3-3,3-3h50c1.7,0,3,1.3,3,3S75.7,127,74,127z"/><path fill="#71c2ff" d="M124,127h-10c-1.7,0-3-1.3-3-3s1.3-3,3-3h10c1.7,0,3,1.3,3,3S125.7,127,124,127z"/><path fill="#444b54" d="M117.3,57.7c-12.9-12.9-33.8-12.9-46.7,0c-12.9,12.9-12.9,33.8,0,46.7L92,125.7c0.6,0.6,1.3,0.9,2.1,0.9 c0,0,0,0,0,0c0.8,0,1.6-0.3,2.1-0.9l21.1-21.3C130.2,91.5,130.2,70.5,117.3,57.7z"/><circle cx="94" cy="80.5" r="10" fill="#fff"/><circle cx="24" cy="19" r="3" fill="#71c2ff"/></svg>
  );
};

ShopLocation.defaultProps = {
  width: 30
};

export default ShopLocation;
