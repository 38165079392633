export const SUB_MENU_TYPES = {
  default: 'default',
  split: 'split'
};

const CONFIG = {
  header: {
    message: `Welcome to Valley Credit Union's new website! For more information, click here.`,
    button: {
      to: '/',
      label: 'Access Online Banking'
    },
    menu: [
      {
        id: 'checking-savings',
        label: 'Checking & Savings',
        subMenu: {
          type: SUB_MENU_TYPES.default,
          items: [
            {
              icon: null,
              label: 'Checking Accounts',
              to: '/',
              description: 'Get a better understanding of where your traffic is coming from'
            },
            {
              icon: null,
              label: 'Savings Accounts',
              to: '/',
              description: 'Speak directly to your customers in a more meaningful way'
            },
            {
              icon: null,
              label: 'Other Accounts',
              to: '/',
              description: 'Your customers data will be safe'
            },
          ],
          bottomLink: {
            to: '/',
            label: 'Enterprise',
            description: 'Empower your entire team with even more advanced tools'
          }
        }
      },
      {
        id: 'loans-creditcards',
        label: 'Loans & Credit Cards',
        subMenu: {
          type: SUB_MENU_TYPES.default,
          items: [
            {
              icon: null,
              label: 'Analytics',
              to: '/',
              description: 'Get a better understanding of where your traffic is coming from'
            },
            {
              icon: null,
              label: 'Engagement',
              to: '/',
              description: 'Speak directly to your customers in a more meaningful way'
            },
            {
              icon: null,
              label: 'Security',
              to: '/',
              description: 'Your customers data will be safe'
            },
          ]
        }
      },
      {
        id: 'services',
        label: 'Services',
        subMenu: {
          type: SUB_MENU_TYPES.default,
          items: [
            {
              icon: null,
              label: 'Analytics',
              to: '/',
              description: 'Get a better understanding of where your traffic is coming from'
            },
            {
              icon: null,
              label: 'Engagement',
              to: '/',
              description: 'Speak directly to your customers in a more meaningful way'
            },
            {
              icon: null,
              label: 'Security',
              to: '/',
              description: 'Your customers data will be safe'
            },
          ]
        }
      },
      {
        id: 'about',
        label: 'About'
      }
    ]
  }
};

export default CONFIG;
