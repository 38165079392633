import React from 'react';

const Visa = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#FFF" d="M109,119H19c-5.5,0-10-4.5-10-10V59c0-5.5,4.5-10,10-10h90c5.5,0,10,4.5,10,10v50C119,114.5,114.5,119,109,119z"/><path fill="#C7D7E2" d="M9 59H119V74H9zM19 89H31V95H19zM19 99H66V105H19zM37 89H49V95H37zM55 89H67V95H55zM73 89H85V95H73z"/><path fill="#454B54" d="M109,122H19c-7.2,0-13-5.8-13-13V59c0-7.2,5.8-13,13-13h90c7.2,0,13,5.8,13,13v50C122,116.2,116.2,122,109,122z M19,52c-3.9,0-7,3.1-7,7v50c0,3.9,3.1,7,7,7h90c3.9,0,7-3.1,7-7V59c0-3.9-3.1-7-7-7H19z"/></svg>
  );
};

Visa.defaultProps = {
  width: 30
};

export default Visa;
