import React from 'react';

import NCUA from '../../images/ncua.svg';
import EHO from '../../images/eho.png';

const AgencyLogos = () => {
  return (
    <div className="w-full flex">
      <div className="mr-4" style={{ width: 80 }}>
        <img src={NCUA} alt="National Credit Union Administration" />
      </div>
      <div className="mr-4" style={{ width: 40 }}>
        <img src={EHO} alt="Equal Housing Opportunity" />
      </div>
    </div>
  );
};

export default AgencyLogos;
