import React from 'react';
import { useStateObject } from '../../utils/State';
import Field from '../Shared/Field';
import LinkButton from '../Shared/LinkButton';

const fields = {
	name: {
		name: 'name',
		label: 'Full Name',
		type: 'text',
		validators: [],
		formatters: []
	},
	email: {
		name: 'email',
		label: 'Email',
		type: 'email',
		validators: [],
		formatters: []
	},
	phone: {
		name: 'phone',
		label: 'Phone',
		type: 'tel',
		validators: [],
		formatters: []
	},
	message: {
		name: 'message',
		label: 'Message',
		type: 'textarea',
		validators: [],
		formatters: []
	}
};

const ContactForm = () => {
	const [formData, setFormData] = useStateObject(() => {
		const data = {};
		Object.values(fields).map(field => {
			data[field.name] = field.defaultValue;
		});

		return data;
	});

	const handleChange = React.useCallback((e) => {
		const name = e.target.name;
		let value = e.target.value;

		fields[name].formatters.forEach(formatter => {
			value = formatter(value);
		});

		setFormData({
			[name]: value
		});
	}, [setFormData]);

	const handleFormSubmit = React.useCallback((e) => {
		e.preventDefault();
		console.log(formData);
	}, []);

	return (
		<div className="w-full pt-4 pb-6">
			<h4 className="text-4xl font-bold mb-2">Get In Touch</h4>
			<div className="bg-periwinkle-base" style={{ width: 140, height: 5 }} />
			<form className="mt-6">
				{Object.values(fields).map(field => (
					<Field
						key={field.name}
						onChange={handleChange}
						value={formData[field.name]}
						{...field}
					/>
				))}
				<LinkButton.Periwinkle
					is="button"
					className="mt-2"
					onClick={handleFormSubmit}
				>
					Submit
				</LinkButton.Periwinkle>
			</form>
		</div>
	);
};

export default ContactForm;
