import React from 'react';

const Deposit = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#f8e390" d="M64,4c19.3,0,35,15.7,35,35c0,19.3-15.7,35-35,35S29,58.3,29,39C29,19.7,44.7,4,64,4"/><path fill="#fcf1c8" d="M64,4L64,4C44.7,4,29,19.7,29,39l0,0c0,19.3,15.7,35,35,35V4L64,4"/><path fill="#fff" d="M64 14A25 25 0 1 0 64 64A25 25 0 1 0 64 14Z"/><path fill="#444b54" d="M64,77c-21,0-38-17-38-38S43,1,64,1s38,17,38,38S85,77,64,77z M64,7C46.4,7,32,21.4,32,39 s14.4,32,32,32s32-14.4,32-32S81.6,7,64,7z"/><path fill="#f8e390" d="M63.5,54.4H63c-3.6,0-6.9-2-8.5-5.2l-0.2-0.4c-0.7-1.5-0.1-3.3,1.3-4c1.5-0.7,3.3-0.1,4,1.3l0.2,0.4 c0.6,1.2,1.8,1.9,3.1,1.9h0.5c1.9,0,3.5-1.6,3.5-3.5s-1.6-3.5-3.5-3.5H58c-1.7,0-3-1.3-3-3v-10c0-1.7,1.3-3,3-3h10c1.7,0,3,1.3,3,3 s-1.3,3-3,3h-7v4h2.5c5.2,0,9.5,4.3,9.5,9.5S68.7,54.4,63.5,54.4z"/><path fill="#444b54" d="M104,127H24c-7.2,0-13-5.8-13-13V94c0-1.7,1.3-3,3-3s3,1.3,3,3v20c0,3.9,3.1,7,7,7h80 c3.9,0,7-3.1,7-7V94c0-1.7,1.3-3,3-3s3,1.3,3,3v20C117,121.2,111.2,127,104,127z"/><path fill="#444b54" d="M64,112c-1.7,0-3-1.3-3-3V89c0-1.7,1.3-3,3-3c1.7,0,3,1.3,3,3v20C67,110.7,65.7,112,64,112z"/><path fill="#444b54" d="M64,112c-0.8,0-1.5-0.3-2.1-0.9l-10-10c-1.2-1.2-1.2-3.1,0-4.2c1.2-1.2,3.1-1.2,4.2,0l7.9,7.9 l7.9-7.9c1.2-1.2,3.1-1.2,4.2,0c1.2,1.2,1.2,3.1,0,4.2l-10,10C65.5,111.7,64.8,112,64,112z"/></svg>
  );
};

Deposit.defaultProps = {
  width: 30
};

export default Deposit;
