import React from 'react';

const FemaleUser = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M87,37c0,0-16.8,0-23,0c-12.7,0-23,10.3-23,23c0,12.7,10.3,23,23,23c12.7,0,23-10.3,23-23 C87,55.7,87,37,87,37z"/><path fill="#ffa9be" d="M26.9,115.6C34.3,102.7,48.1,94,64,94h0c15.9,0,29.7,8.7,37.1,21.6L101,119H27L26.9,115.6z"/><path fill="#444b54" d="M101.1 118.6c-1 0-2.1-.5-2.6-1.5C91.4 104.7 78.2 97 64 97c-14.2 0-27.4 7.7-34.5 20.1-.8 1.4-2.7 1.9-4.1 1.1s-1.9-2.7-1.1-4.1C32.4 99.9 47.6 91 64 91c16.4 0 31.6 8.9 39.7 23.1.8 1.4.3 3.3-1.1 4.1C102.1 118.5 101.6 118.6 101.1 118.6zM47.5 76c1.3-.8 1.4-2.8.5-4.1C45.4 68.5 44 64.3 44 60l0 0c0-1.1.9-1.9 1.9-1.9h7.2c3.1 0 6.4-.4 9.7-1.4 6.6-2 12-6 15.3-10.7 0 0 0 0 0 0 .4-.6.8-1.1 1.1-1.7.8-1.4 2.6-2.1 4-1.4 1.6.8 2.1 2.7 1.3 4.2-.5.9-1.1 1.8-1.7 2.6-.6.8-.7 1.9-.3 2.9 1 2.4 1.5 5.1 1.4 7.9-.2 9.4-7.2 17.6-16.5 19.2-3 .5-6 .4-8.9-.4-1.5-.4-3.2.3-3.8 1.8-.6 1.6.3 3.4 2 3.9 2.3.7 4.7 1 7.2 1 7.7 0 14.6-3.4 19.4-8.7 2 .7 4.2.9 6.6.6 7-.9 12.5-6.8 13-13.8.3-3.8-.9-7.2-2.9-10 3.2-2.9 5.1-7.1 4.9-11.7-.2-6-4.2-11.3-9.9-13.4-3-1.1-5.8-1.2-8.4-.6C81.9 20.9 73.5 16 64 16c-9.5 0-17.9 4.9-22.7 12.4-2.6-.6-5.5-.6-8.5.6-5.6 2.2-9.6 7.4-9.8 13.5-.2 4.6 1.8 8.8 4.9 11.7-2.2 3-3.3 6.7-2.8 10.8.8 6.7 6.2 12.1 12.9 13 3 .4 5.8-.1 8.2-1.2C46.2 76.6 46.9 76.3 47.5 76z"/></svg>
  );
};

FemaleUser.defaultProps = {
  width: 30
};

export default FemaleUser;
