import React from 'react';

const MerchantAccount4 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M14 53H26V112H14zM58 53H70V112H58zM102 53H114V92H102zM84 12.5h-1.4c-4.9-4.4-11.4-7.1-18.5-7.1s-13.6 2.7-18.5 7.1H45 4V43l120-.5v-30H84z"/><path fill="#71c2ff" d="M56 127H4c-1.7 0-3-1.3-3-3s1.3-3 3-3h52c1.7 0 3 1.3 3 3S57.7 127 56 127zM26 115H14c-1.7 0-3-1.3-3-3V52.5c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3V112C29 113.7 27.7 115 26 115zM17 109h6V55.5h-6V109zM70 105c-1.7 0-3-1.3-3-3V55.5h-6V112c0 1.7-1.3 3-3 3s-3-1.3-3-3V52.5c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3V102C73 103.7 71.7 105 70 105zM124 9.5H84c-.2 0-.3 0-.5.1C78.2 5.1 71.4 2.5 64 2.5c-7.3 0-14.1 2.6-19.4 7H4c-1.7 0-3 1.3-3 3V43c0 .8.3 1.6.9 2.1C2.4 45.7 3.2 46 4 46c0 0 0 0 0 0l120-.5c1.7 0 3-1.3 3-3v-30C127 10.8 125.7 9.5 124 9.5zM121 29.5H94.3c-.6-5.2-2.5-10-5.3-14h32V29.5zM88.2 29.5H39.8c1.7-11.9 12-21 24.2-21C76.3 8.5 86.5 17.6 88.2 29.5zM39 15.5c-2.9 4-4.8 8.8-5.3 14H7v-14H39zM7 40v-4.5h114v4L7 40z"/><g><path fill="#444b54" d="M99 101.8c9.4 0 17-7.6 17-17s-7.6-17-17-17-17 7.6-17 17S89.6 101.8 99 101.8zM126.8 123.2c-5.7-10-16.3-16.2-27.8-16.2-11.5 0-22.1 6.2-27.8 16.2-.8 1.4-.3 3.3 1.1 4.1.5.3 1 .4 1.5.4 0 0 0 0 0 0l50.4 0v0c.5 0 1-.1 1.5-.4C127.1 126.5 127.6 124.6 126.8 123.2z"/></g><g><path fill="#71c2ff" d="M114,63c-1.7,0-3-1.3-3-3v-4.5h-6V57c0,1.7-1.3,3-3,3s-3-1.3-3-3v-4.5c0-1.7,1.3-3,3-3h12c1.7,0,3,1.3,3,3V60 C117,61.7,115.7,63,114,63z"/></g></svg>
  );
};

MerchantAccount4.defaultProps = {
  width: 30
};

export default MerchantAccount4;
