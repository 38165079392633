import React from 'react';

const MoneyCirculation = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M64 9A55 55 0 1 0 64 119A55 55 0 1 0 64 9Z"/><path fill="#00d6c6" d="M64 24A40 40 0 1 0 64 104A40 40 0 1 0 64 24Z" transform="rotate(-45.001 64 64.001)"/><path fill="#444b54" d="M117.1 40.6c-.7-1.5-2.4-2.2-4-1.5-1.5.7-2.2 2.4-1.5 4 8.7 19.8 4.5 42.5-10.8 57.8C90.9 110.6 77.9 116 64 116c-11.2 0-21.9-3.5-30.8-10.1l0 0h4.9c1.7 0 3-1.3 3-3s-1.3-3-3-3h-13c-1.7 0-3 1.3-3 3v13c0 1.7 1.3 3 3 3s3-1.3 3-3v-6.3l0 0C38.6 117.8 51.3 122 64 122c14.9 0 29.7-5.7 41-17C122.1 88 126.8 62.7 117.1 40.6zM102.9 9.1c-1.7 0-3 1.3-3 3v6.3l0 0C77.1.5 44 2 23 23 6 39.9 1.2 65.1 10.8 87.1c.5 1.1 1.6 1.8 2.8 1.8.4 0 .8-.1 1.2-.2 1.5-.7 2.2-2.4 1.6-3.9C7.7 65 12 42.4 27.2 27.2 37.1 17.4 50.1 12 64 12c11.2 0 21.9 3.5 30.8 10.1l0 0h-4.9c-1.7 0-3 1.3-3 3s1.3 3 3 3h13c1.7 0 3-1.3 3-3v-13C105.9 10.5 104.5 9.1 102.9 9.1z"/><path fill="#fff" d="M75.1,73.9c0-3.7-1.8-7.1-4.8-9.2L61,58.3c-1.3-1-2.1-2.5-2.1-4.2c0-2.8,2.3-5.1,5.1-5.1 c2.1,0,3.9,1.2,4.7,3.1c0.6,1.5,2.4,2.2,3.9,1.6c1.5-0.6,2.2-2.4,1.6-3.9c-1.3-3.2-4-5.5-7.2-6.4V43c0-1.7-1.3-3-3-3s-3,1.3-3,3 v0.4c-4.7,1.3-8.1,5.6-8.1,10.7c0,3.6,1.7,6.9,4.7,9l9.3,6.5c1.4,1,2.2,2.5,2.2,4.2c0,2.8-2.3,5.1-5.1,5.1c-2,0-3.9-1.2-4.7-3.1 c-0.7-1.5-2.4-2.2-3.9-1.6c-1.5,0.7-2.2,2.4-1.6,3.9c1.4,3.1,4,5.4,7.2,6.3V85c0,1.7,1.3,3,3,3s3-1.3,3-3v-0.4 C71.7,83.3,75.1,79,75.1,73.9z"/></svg>
  );
};

MoneyCirculation.defaultProps = {
  width: 30
};

export default MoneyCirculation;
