import React from 'react';

const OnlinePayment = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M114,124H14c-5.5,0-10-4.5-10-10V14C4,8.5,8.5,4,14,4h100c5.5,0,10,4.5,10,10v100C124,119.5,119.5,124,114,124z"/><path fill="#444b54" d="M114,127H14c-7.2,0-13-5.8-13-13V41c0-1.7,1.3-3,3-3s3,1.3,3,3v73c0,3.9,3.1,7,7,7h100c3.9,0,7-3.1,7-7V14c0-3.9-3.1-7-7-7H14c-3.9,0-7,3.1-7,7v11h104c1.7,0,3,1.3,3,3s-1.3,3-3,3H4c-1.7,0-3-1.3-3-3V14C1,6.8,6.8,1,14,1h100c7.2,0,13,5.8,13,13v100C127,121.2,121.2,127,114,127z"/><path fill="#444b54" d="M16 13A3 3 0 1 0 16 19 3 3 0 1 0 16 13zM26 13A3 3 0 1 0 26 19 3 3 0 1 0 26 13zM36 13A3 3 0 1 0 36 19 3 3 0 1 0 36 13z"/><path fill="#71c2ff" d="M75.1,85.9c0-3.7-1.8-7.1-4.8-9.2L61,70.3c-1.3-1-2.1-2.5-2.1-4.2c0-2.8,2.3-5.1,5.1-5.1c2.1,0,3.9,1.2,4.7,3.1c0.6,1.5,2.4,2.2,3.9,1.6c1.5-0.6,2.2-2.4,1.6-3.9c-1.3-3.2-4-5.5-7.2-6.4V55c0-1.7-1.3-3-3-3s-3,1.3-3,3v0.4c-4.7,1.3-8.1,5.6-8.1,10.7c0,3.6,1.7,6.9,4.7,9l9.3,6.5c1.4,1,2.3,2.5,2.3,4.2c0,2.8-2.3,5.1-5.1,5.1c-2,0-3.9-1.2-4.7-3.1c-0.7-1.5-2.4-2.2-3.9-1.6c-1.5,0.7-2.2,2.4-1.6,3.9c1.4,3.1,4,5.4,7.2,6.3V97c0,1.7,1.3,3,3,3s3-1.3,3-3v-0.4C71.7,95.3,75.1,91,75.1,85.9z"/></svg>
  );
};

OnlinePayment.defaultProps = {
  width: 30
};

export default OnlinePayment;
