import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "../Shared/Modal/Modal"
import LinkButton from "../Shared/LinkButton"

const ExternalLinkModal = ({ toggleModal, to }) => {
  return (
    <Modal
      title="You're about to leave VCU."
      description="The following link is about to direct you away from the Valley Credit Union website. Are you sure you want to proceed?"
      onRequestClose={toggleModal}
    >
      <div className="w-full flex flex-col md:flex-row">
        <LinkButton.Light
          className="flex-1 md:mr-3 block"
          to="/"
          is="button"
          onClick={toggleModal}
        >
          Cancel
        </LinkButton.Light>
        <LinkButton.Red
          showExternalModal={false}
          className="flex-1 block order-first md:order-none mb-3 md:mb-0"
          to={to}
        >
          Continue
        </LinkButton.Red>
      </div>
    </Modal>
  )
}

ExternalLinkModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
}

export default ExternalLinkModal
