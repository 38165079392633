import React from 'react';

const GeneralLedger = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#3f4a54" d="M44,39H34c-5.5,0-10-4.5-10-10v70c0,5.5,4.5,10,10,10h10h60V39H44z M63.1,69.2l6.2,1.5c4.5,1.1,7.7,5.2,7.7,9.9c0,5-3.7,9.2-8.5,10V92c0,1.7-1.3,3-3,3s-3-1.3-3-3v-1.3C57.7,90,54,85.9,54,80.9c0-1.7,1.3-3,3-3s3,1.3,3,3c0,2.1,1.7,3.8,3.8,3.8l3,0c2.3,0,4.2-1.9,4.2-4.2c0-1.9-1.3-3.6-3.1-4L61.7,75c-4.5-1.1-7.7-5.2-7.7-9.9c0-5,3.7-9.2,8.5-10v-1.4c0-1.7,1.3-3,3-3s3,1.3,3,3v1.3c4.8,0.6,8.5,4.7,8.5,9.7c0,1.7-1.3,3-3,3s-3-1.3-3-3c0-2.1-1.7-3.8-3.8-3.8h-3c-2.3,0-4.2,1.9-4.2,4.2C60,67,61.3,68.7,63.1,69.2z"/><path fill="#3f4a54" d="M44,112H34c-7.2,0-13-5.8-13-13c0-1.7,1.3-3,3-3s3,1.3,3,3c0,3.9,3.1,7,7,7h10c1.7,0,3,1.3,3,3S45.7,112,44,112z"/><path fill="#fff" d="M104,39H34c-5.5,0-10-4.5-10-10v0c0-5.5,4.5-10,10-10h70"/><path fill="#3f4a54" d="M104 112H44c-1.7 0-3-1.3-3-3s1.3-3 3-3h57V42H44c-1.7 0-3-1.3-3-3s1.3-3 3-3h60c1.7 0 3 1.3 3 3v70C107 110.7 105.7 112 104 112zM24 102c-1.7 0-3-1.3-3-3V29c0-1.7 1.3-3 3-3s3 1.3 3 3v70C27 100.7 25.7 102 24 102z"/><path fill="#3f4a54" d="M104,42H34c-7.2,0-13-5.8-13-13s5.8-13,13-13h70c1.7,0,3,1.3,3,3s-1.3,3-3,3H34c-3.9,0-7,3.1-7,7s3.1,7,7,7h70c1.7,0,3,1.3,3,3S105.7,42,104,42z"/></svg>
  );
};

GeneralLedger.defaultProps = {
  width: 30
};

export default GeneralLedger;
