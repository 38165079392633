import React from 'react';

const Sedan = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M85.6,46h-43c-7.6,0-14,5.7-14.9,13.2L25,82h78l-2.5-22.7C99.6,51.7,93.2,46,85.6,46z"/><path fill="#e9eef4" d="M64.2,82H103l-2.5-22.7c-0.2-1.5-0.6-3-1.2-4.3H67.2c-1.7,0-3,1.3-3,3V82z"/><path fill="#676f77" d="M25 74H103V110H25z"/><path fill="#444b54" d="M116,107V83c0-5-4-9-9-9h-1.8l-1.8-15c-1-9.1-8.7-16-17.9-16h-43c-9.1,0-16.8,6.8-17.9,15.9L22.9,74H21c-5,0-9,4-9,9v24c-1.7,0-3,1.3-3,3s1.3,3,3,3h6v11c0,1.7,1.3,3,3,3h12h62h12h3c1.7,0,3-1.3,3-3s-1.3-3-3-3v-8h6c1.7,0,3-1.3,3-3S117.7,107,116,107z M30.7,59.6c0.7-6,5.8-10.6,11.9-10.6h43c6.1,0,11.3,4.6,11.9,10.7l1.4,11.5c-21.3-2.8-48.2-2.8-69.6,0L30.7,59.6z M35,85.2c0.5,0.5,1.2,0.8,2,0.8c1.7,0,3-1.4,3-3c0-2.6-1.1-5-2.9-6.6c8.5-0.8,17.7-1.2,26.9-1.2V107H35V85.2z M92,121H36v-8h56V121z"/><path fill="#fff" d="M102,98H87.2l0,0c0-4.6-3.4-8.4-7.9-9h-0.1c-10.1-1.3-20.4-1.4-30.5-0.3L45,89.1c-4.5,0.5-8,4.3-8,8.9l0,0H26.4c-1.7,0-3,1.3-3,3v6H105v-6C105,99.3,103.7,98,102,98z"/><path fill="#444b54" d="M124,127c-0.8,0-1.6-0.3-2.1-0.9c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.6c0-0.8,0.3-1.6,0.9-2.1c1.1-1.1,3.1-1.1,4.2,0c0.6,0.6,0.9,1.3,0.9,2.1c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.2,0.6s-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.3-0.4,0.4C125.6,126.7,124.8,127,124,127z"/><path fill="#fff" d="M107 86L107 86H97c-1.7 0-3-1.3-3-3s1.3-3 3-3l0 0h10c1.7 0 3 1.3 3 3S108.7 86 107 86zM21 86c-1.7 0-3-1.3-3-3s1.3-3 3-3h10l0 0c1.7 0 3 1.3 3 3s-1.3 3-3 3H21L21 86z"/></svg>
  );
};

Sedan.defaultProps = {
  width: 30
};

export default Sedan;
