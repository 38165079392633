import React from 'react';

const Invoice = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M36,109l10,10l10-10l10,10l10-10l10,10l10-10l10,10V34L81,9H32c-5.5,0-10,4.5-10,10v100"/><path fill="#444b54" d="M108.1,31.9l-25-25C82.6,6.3,81.8,6,81,6H32c-7.2,0-13,5.8-13,13v100c0,1.7,1.3,3,3,3s3-1.3,3-3V19 c0-3.9,3.1-7,7-7h46v12c0,7.2,5.8,13,13,13h12v74.8l-4.9-4.9c-1.2-1.2-3.1-1.2-4.2,0l-7.9,7.9l-7.9-7.9c-1.2-1.2-3.1-1.2-4.2,0 l-7.9,7.9l-7.9-7.9c-1.2-1.2-3.1-1.2-4.2,0l-7.9,7.9l-7.9-7.9c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2l10,10 c1.2,1.2,3.1,1.2,4.2,0l7.9-7.9l7.9,7.9c1.2,1.2,3.1,1.2,4.2,0l7.9-7.9l7.9,7.9c1.2,1.2,3.1,1.2,4.2,0l7.9-7.9l7.9,7.9 c0.6,0.6,1.3,0.9,2.1,0.9c0.4,0,0.8-0.1,1.1-0.2c1.1-0.5,1.9-1.6,1.9-2.8V34C109,33.2,108.7,32.4,108.1,31.9z M84,24v-7.8L98.8,31 H91C87.1,31,84,27.9,84,24z"/><path fill="#ff5576" d="M86,97H75c-1.7,0-3-1.3-3-3s1.3-3,3-3h11c1.7,0,3,1.3,3,3S87.7,97,86,97z"/><path fill="#9eb9d3" d="M86 58H62c-1.7 0-3-1.3-3-3s1.3-3 3-3h24c1.7 0 3 1.3 3 3S87.7 58 86 58zM86 70H62c-1.7 0-3-1.3-3-3s1.3-3 3-3h24c1.7 0 3 1.3 3 3S87.7 70 86 70zM86 82H62c-1.7 0-3-1.3-3-3s1.3-3 3-3h24c1.7 0 3 1.3 3 3S87.7 82 86 82zM50 58h-8c-1.7 0-3-1.3-3-3s1.3-3 3-3h8c1.7 0 3 1.3 3 3S51.7 58 50 58zM50 70h-8c-1.7 0-3-1.3-3-3s1.3-3 3-3h8c1.7 0 3 1.3 3 3S51.7 70 50 70zM50 82h-8c-1.7 0-3-1.3-3-3s1.3-3 3-3h8c1.7 0 3 1.3 3 3S51.7 82 50 82z"/></svg>
  );
};

Invoice.defaultProps = {
  width: 30
};

export default Invoice;
