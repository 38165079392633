import React from 'react';

const Wallet2 = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#fff" d="M114,124H14c-5.5,0-10-4.5-10-10V44V24L105.3,6.6c5.4-1,10.6,2.7,11.6,8.1l6.9,39.4L124,114 C124,119.5,119.5,124,114,124z"/><path fill="#71c2ff" d="M53.8,34l62.9,0c1.9,0,3.3-1.7,3-3.5l-1.6-8.8c-0.3-1.6-1.8-2.7-3.5-2.4L53.5,30C51.1,30.5,51.4,34,53.8,34z"/><path fill="#444b54" d="M114,127H14c-7.2,0-13-5.8-13-13V55c0-1.7,1.3-3,3-3s3,1.3,3,3v59c0,3.9,3.1,7,7,7h100c3.9,0,7-3.1,7-7V54 c0-3.9-3.1-7-7-7H14.9C7.2,47,1,40.8,1,33.1c0-6.8,4.8-12.5,11.5-13.7l92.3-15.9c3.4-0.6,6.9,0.2,9.7,2.2c2.8,2,4.7,5,5.3,8.4 l3.1,15.2c0.3,1.6-0.7,3.2-2.3,3.5c-1.6,0.3-3.2-0.7-3.5-2.3L114,15.3c-0.3-1.9-1.4-3.5-2.9-4.6c-1.5-1.1-3.4-1.5-5.2-1.2 L13.5,25.4C9.7,26.1,7,29.3,7,33.1c0,4.3,3.5,7.9,7.9,7.9H114c7.2,0,13,5.8,13,13v60C127,121.2,121.2,127,114,127z"/><path fill="#71c2ff" d="M101,73c-6.1,0-11,4.9-11,11s4.9,11,11,11s11-4.9,11-11S107.1,73,101,73z"/></svg>
  );
};

Wallet2.defaultProps = {
  width: 30
};

export default Wallet2;
