import React from 'react';

const Bonds = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#6ec6af" d="M109,119H19c-5.5,0-10-4.5-10-10V19c0-5.5,4.5-10,10-10h90c5.5,0,10,4.5,10,10v90 C119,114.5,114.5,119,109,119"/><path fill="#c5e8df" d="M64,64c-8.284,0-15-6.716-15-15s6.716-15,15-15c8.284,0,15,6.716,15,15S72.284,64,64,64 M99,19H29 c0,5.5-4.5,10-10,10v70c5.5,0,10,4.5,10,10h70c0-5.5,4.5-10,10-10V29C103.5,29,99,24.5,99,19"/><path fill="#eef8f5" d="M64,34c-8.284,0-15,6.716-15,15s6.716,15,15,15c8.284,0,15-6.716,15-15S72.284,34,64,34"/><path fill="#fff" d="M96 79H32c-1.7 0-3-1.3-3-3s1.3-3 3-3h64c1.7 0 3 1.3 3 3S97.7 79 96 79zM89 91H39c-1.7 0-3-1.3-3-3s1.3-3 3-3h50c1.7 0 3 1.3 3 3S90.7 91 89 91z"/><path fill="#444b54" d="M109,122H19c-7.2,0-13-5.8-13-13V19c0-7.2,5.8-13,13-13h90c7.2,0,13,5.8,13,13v90 C122,116.2,116.2,122,109,122z M19,12c-3.9,0-7,3.1-7,7v90c0,3.9,3.1,7,7,7h90c3.9,0,7-3.1,7-7V19c0-3.9-3.1-7-7-7H19z"/></svg>
  );
};

Bonds.defaultProps = {
  width: 30
};

export default Bonds;
