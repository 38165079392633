import React from 'react';

const PickupFrontView = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}><path fill="#444b54" d="M124 121A3 3 0 1 0 124 127A3 3 0 1 0 124 121Z"/><path fill="#fff" d="M80.6,27h-43c-7.6,0-14,5.7-14.9,13.2L20,63h78l-2.5-22.7C94.6,32.7,88.2,27,80.6,27z"/><path fill="#e9eef4" d="M59.2,63H98l-2.5-22.7c-0.2-1.5-0.6-3-1.2-4.3H62.2c-1.7,0-3,1.3-3,3V63z"/><path fill="#ff5576" d="M98,57H20c-5.5,0-10,4.5-10,10l10,28h78l10-28C108,61.5,103.5,57,98,57z"/><path fill="#c6345e" d="M98,57H59v38h39l10-28C108,61.5,103.5,57,98,57z"/><path fill="#fff" d="M22 66.8H108V85H22z"/><path fill="#e9eef4" d="M59 66.8H89V85H59z"/><path fill="#fff" d="M10 84.8H108V95H10z"/><path fill="#e9eef4" d="M10 66.8H32V85H10z"/><path fill="#d3dae0" d="M86 66.8H108V85H86z"/><path fill="#444b54" d="M100,54.2L98.5,40c-1-9.1-8.7-16-17.9-16h-43c-9.1,0-16.8,6.8-17.9,15.9L18,54.2c-6.2,1-11,6.3-11,12.8v10c0,1.7,1.3,3,3,3s3-1.3,3-3V67c0-3.9,3.1-7,7-7h0.7l0,0l0,0H98c3.9,0,7,3.1,7,7v10c0,1.7,1.3,3,3,3s3-1.3,3-3V67C111,60.5,106.2,55.2,100,54.2z M25.7,40.6c0.7-6,5.8-10.6,11.9-10.6h43c6.1,0,11.3,4.6,11.9,10.7L94,54H24.1L25.7,40.6z"/><path fill="#fff" d="M22.5 69.5A5 5 0 1 0 22.5 79.5 5 5 0 1 0 22.5 69.5zM96.5 69.5A5 5 0 1 0 96.5 79.5 5 5 0 1 0 96.5 69.5z"/><path fill="#444b54" d="M110,121h-5V97.7c3.4-0.9,6-4,6-7.7c0-4.4-3.6-8-8-8H15c-4.4,0-8,3.6-8,8c0,3.7,2.6,6.8,6,7.7V124c0,1.7,1.3,3,3,3h12h62h12h8c1.7,0,3-1.3,3-3S111.7,121,110,121z M55.1,98c-1.6,0.9-2.9,2.3-3.5,4H31v-4H55.1z M87,102H66.4c-0.7-1.7-2-3.1-3.5-4H87V102z M31,108h20.6c1.2,2.9,4.1,5,7.4,5s6.2-2.1,7.4-5H87v13H31V108z M15,88h88c1.1,0,2,0.9,2,2s-0.9,2-2,2H15c-1.1,0-2-0.9-2-2S13.9,88,15,88z"/></svg>
  );
};

PickupFrontView.defaultProps = {
  width: 30
};

export default PickupFrontView;
