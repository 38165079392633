import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import ExternalLink from "./ExternalLink"

const MODAL_FREE_EXTERNAL_DOMAINS = [
  "cue-branch.com/vcu/Login.aspx",
  "cue-branch.com/vcu/SelfSignUp.aspx",
  "cue-branch.com/vcu/AccountReset.aspx",
  "valleycreditunion.digitalaccountopening.net",
]

// Don't show the external link modal on a certain
// permitted list of domains
const shouldSkipExternalLinkModal = to => {
  let shouldSkip = false

  MODAL_FREE_EXTERNAL_DOMAINS.forEach(domain => {
    if (to.indexOf(domain) !== -1) {
      shouldSkip = true
    }
  })

  return shouldSkip
}

const Link = ({ children, to, showExternalModal, ...rest }) => {
  if (!to) {
    return null
  }

  const isHttp = to.indexOf("http://") === 0 || to.indexOf("https://") === 0
  const isTel = to.indexOf("tel:") === 0
  const isMailto = to.indexOf("mailto:") === 0
  const isDubs = to.indexOf("www.") === 0
  const isExternalLink = isHttp || isDubs || isMailto || isTel

  // Prepend http to the external link if necessary
  const linkHref = isDubs ? `http://${to}` : to

  if (isExternalLink) {
    // Renders a link with a popup modal to confirm leaving the page
    if (
      (showExternalModal && !shouldSkipExternalLinkModal(to) && isHttp) ||
      isDubs
    ) {
      return (
        <ExternalLink to={linkHref} {...rest}>
          {children}
        </ExternalLink>
      )
    }

    return (
      <a href={linkHref} {...rest}>
        {children}
      </a>
    )
  }

  // Add slash in front of local link if necessary
  to = to[0] === "/" ? to : `/${to}`

  return (
    <GatsbyLink to={to} {...rest}>
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string.isRequired,
  showExternalModal: PropTypes.bool,
}

Link.defaultProps = {
  showExternalModal: true,
}

export default Link
