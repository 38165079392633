import React from 'react';
import Link from '../Link/Link';
import PropTypes from 'prop-types';
import { useClassNames } from '../../utils/Class';
import { makeVariant } from '../../utils/Variant';

const BASE_CLASS = `
  whitespace-no-wrap
  items-center
  justify-center
  px-4
  py-3
  border
  border-transparent
  rounded
  text-base
  leading-6
  font-bold
  text-white
  focus:outline-none
  transition ease-in-out duration-150
`;

const RED_CLASS = `
  bg-red-base
  hover:bg-red-light
  focus:border-red-light
  focus:shadow-outline-red-base
  active:bg-red-light
`;

const PERIWINKLE_CLASS = `
  bg-periwinkle-base
  hover:bg-periwinkle-light
  active:bg-periwinkle-light
`;

const BLUE_CLASS = `
  bg-blue-base
  hover:bg-blue-light
  active:bg-blue-light
`;

const WHITE_CLASS = `
  text-navy-base
  bg-white
  hover:bg-gray-50
`;

const LIGHT_CLASS = `
  bg-white
  text-gray-700
  border
  border-gray-300
  hover:bg-gray-50
`;

const LinkButton = ({ is, to, className, children, ...rest }) => {
  const fullClass = useClassNames(BASE_CLASS, className);

  const Component = is || Link;

  return (
    <Component
      to={to}
      className={fullClass}
      {...rest}
    >
      {children}
    </Component>
  );
};

LinkButton.propTypes = {
  is: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

LinkButton.defaultProps = {
  className: ''
};

LinkButton.Red = makeVariant(LinkButton, RED_CLASS);
LinkButton.Blue = makeVariant(LinkButton, BLUE_CLASS);
LinkButton.Periwinkle = makeVariant(LinkButton, PERIWINKLE_CLASS);
LinkButton.White = makeVariant(LinkButton, WHITE_CLASS);
LinkButton.Light = makeVariant(LinkButton, LIGHT_CLASS);

export default LinkButton;
